import React, { memo, useMemo } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import { IconClock } from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { Notice } from '@noloco/components/src';
import { PRIMARY } from '@noloco/components/src/constants/variants';
import { CANCELED } from '@noloco/core/src/constants/accountStatus';
import {
  BillingOrTeamPlan,
  BillingPlan,
} from '@noloco/core/src/models/BillingPlan';
import {
  isTrailingPlanExpired,
  isTrialActive,
} from '@noloco/core/src/utils/billing';
import { getText } from '@noloco/core/src/utils/lang';

type TrialBannerProps = {
  children: any;
  plan: BillingPlan;
  href?: string;
  to?: string;
  target?: string;
  rel?: string;
  linkIs?: any;
};

const TrialBanner = memo(
  ({ children, plan, linkIs, ...rest }: TrialBannerProps) => {
    const hasActiveTrial = isTrialActive(plan as BillingOrTeamPlan);

    const trialHasExpired = isTrailingPlanExpired(plan);
    const isCancelled = useMemo(
      () => plan?.status === CANCELED,
      [plan?.status],
    );

    const remainingTrialDays = hasActiveTrial
      ? Math.ceil(
          DateTime.fromISO(plan.trialEnd as string)
            .diffNow(['days'])
            .toObject().days as number,
        )
      : null;

    if (hasActiveTrial || trialHasExpired || isCancelled) {
      return (
        <Notice
          className="my-6"
          type={PRIMARY}
          icon={IconClock}
          title={getText(
            'billing.trial',
            trialHasExpired ? 'expired' : isCancelled ? 'canceled' : 'free',
            'title',
          )}
          subtitle={getText(
            {
              remainingDays: remainingTrialDays as number,
              endDate: DateTime.fromISO(plan.trialEnd as string).toLocaleString(
                DateTime.DATE_MED,
              ),
            },
            'billing.trial',
            trialHasExpired ? 'expired' : isCancelled ? 'canceled' : 'free',
            'description',
          )}
        >
          <div className="ml-6 flex items-center justify-center">
            <Box
              is={linkIs}
              {...rest}
              className="flex whitespace-nowrap rounded-lg bg-pink-300 px-3 py-2 text-sm font-medium text-pink-800 hover:bg-pink-200 hover:text-pink-700"
            >
              {children}
            </Box>
          </div>
        </Notice>
      );
    }

    return null;
  },
);

export default TrialBanner;
