import freeEmailDomains from 'free-email-domains';
import { getDomain } from './emails';
import { getText } from './lang';

const NUM_ADJECTIVES = 24;

export const getProjectNamePlaceholder = (email: any, firstName: any) => {
  const emailDomain = getDomain(email);

  if (!freeEmailDomains.includes(emailDomain)) {
    return emailDomain.split('.').slice(0, 1).join('');
  }

  const adjective = getText(
    'newProject.name.adjectives',
    Math.floor(Math.random() * NUM_ADJECTIVES),
  );

  return `${firstName}s-${adjective}-app`;
};
