import React, { useMemo } from 'react';
import { SelectInput } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import {
  CUSTOMER_PORTAL,
  INTERNAL_TOOL,
  PlanProduct,
} from '@noloco/core/src/constants/accountPlanProducts';
import { getText } from '@noloco/core/src/utils/lang';
import { getProjectDomain } from '@noloco/core/src/utils/pages';

type Props = {
  customerPortalActivationCost: number;
  disabled: boolean;
  product: PlanProduct;
  projectName: string;
  onSelectProduct: (projectName: string) => (product: PlanProduct) => void;
};

export const ProductButton = ({ value }: { value: PlanProduct }) => (
  <span className="block truncate">
    <span className="text-sm text-black">
      {getText('billing.plan.product', value, 'title')}
    </span>
  </span>
);

const ProjectProduct = ({
  customerPortalActivationCost,
  disabled,
  product,
  projectName,
  onSelectProduct,
}: Props) => {
  const onSelect = useMemo(
    () => onSelectProduct(projectName),
    [onSelectProduct, projectName],
  );

  const productDropdownOptions = useMemo(
    () => [
      {
        help: getText('billing.plan.product', INTERNAL_TOOL, 'subtitle'),
        label: getText('billing.plan.product', INTERNAL_TOOL, 'title'),
        value: INTERNAL_TOOL,
      },
      {
        help: getText('billing.plan.product', CUSTOMER_PORTAL, 'subtitle'),
        label: `${getText(
          'billing.plan.product',
          CUSTOMER_PORTAL,
          'title',
        )} (+$${customerPortalActivationCost})`,
        value: CUSTOMER_PORTAL,
      },
    ],
    [customerPortalActivationCost],
  );

  return (
    <div className="row flex">
      <div className="flex w-3/5">
        <a
          className="my-auto text-sm text-blue-500 hover:text-blue-800 hover:underline"
          href={getProjectDomain(projectName)}
          target="_blank"
          rel="noreferrer"
        >
          {projectName}
        </a>
      </div>
      <div className="flex w-2/5">
        <SelectInput
          Button={ProductButton}
          className="w-full"
          disabled={disabled}
          onChange={onSelect}
          options={productDropdownOptions}
          readOnly={disabled}
          size={SM}
          surface={LIGHT}
          value={product}
        />
      </div>
    </div>
  );
};

export default ProjectProduct;
