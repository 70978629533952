import gql from 'graphql-tag';
import { PLAN_FRAGMENT, userFragment } from '@noloco/core/src/queries/auth';

export const ADD_PAID_PLAN_QUERY = gql`
  mutation addPaidPlan(
    $teamId: Int!
    $addons: [ExternalUsersInputWrapper!],
    $planType: PlanType!, 
    $paymentMethodId: String!, 
    $promotionCode: String, 
    $referralId: String,
    $interval: PlanInterval
  ) {
    addPaidPlan(
      teamId: $teamId,
      planType: $planType,
      paymentMethodId: $paymentMethodId,
      promotionCode: $promotionCode
      referralId: $referralId
      addons: $addons
      interval: $interval
    ) {
      token
      user {
        ${userFragment}
      }
    }
  }
`;

export const CHANGE_PLAN_QUERY = gql`
  mutation changePlan($teamId: Int!, $planType: PlanType!, $addons: [ExternalUsersInputWrapper!], $interval: PlanInterval) {
    changePlan(teamId: $teamId, planType: $planType, addons: $addons, interval: $interval) {
      token
      user {
        ${userFragment}
      }
    }
  }
`;

export const OPEN_CUSTOMER_PORTAL_QUERY = gql`
  mutation openCustomerPortal($teamId: Int!, $returnUrl: String!) {
    openCustomerPortal(teamId: $teamId, returnUrl: $returnUrl) {
      id
      portalUrl
    }
  }
`;

export const CONFIRM_PAYMENT_QUERY = gql`
  mutation confirmPaymentIntent($paymentIntentId: String!, $planType: PlanType!) {
    confirmPaymentIntent(paymentIntentId: $paymentIntentId, planType: $planType) {
      token
      user {
        ${userFragment}
      }
    }
  }
`;

export const CONTINUE_ON_FREE = gql`
  mutation continueOnFree {
    continueOnFree {
      ${PLAN_FRAGMENT}
    }
  }
`;

export const UPDATE_EXTERNAL_USERS_ADDON = gql`
  mutation updateExternalUsersAddon(
    $projectName: String!
    $addon: ExternalUsersInput!
  ) {
    updateExternalUsersAddon(projectName: $projectName, addon: $addon) {
      addons {
        externalUsers {
          enabled
          domains
        }
      }
    }
  }
`;
