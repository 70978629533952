import React from 'react';
import WorkspaceList from './WorkspaceList';
import WorkspaceSettings from './WorkspaceSettings';

const WorkspaceSettingsList = () => {
  return (
    <div className="flex md:mb-2 md:flex-col">
      <WorkspaceList redirectToFirstWorkspace={true} path="/workspaces" />
      <WorkspaceSettings />
    </div>
  );
};

export default WorkspaceSettingsList;
