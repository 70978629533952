import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation } from '@apollo/client';
import {
  IconAlertCircleFilled,
  IconCircleCheck,
  IconPencil,
  IconWand,
} from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import {
  AutoSizedTextInput,
  Badge,
  Button,
  Loader,
  Tooltip,
} from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { LG } from '@noloco/components/src/constants/tShirtSizes';
import builtInDataTypes from '@noloco/core/src/constants/builtInDataTypes';
import { TABLE } from '@noloco/core/src/constants/collectionLayouts';
import DataFieldOptions from '@noloco/core/src/models/DataFieldOptions';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import DataTypes, {
  DataType,
  DataTypeArray,
} from '@noloco/core/src/models/DataTypes';
import { BaseRecord } from '@noloco/core/src/models/Record';
import { getDataTypesWithRelations } from '@noloco/core/src/utils/data';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import usePromiseQuery from '@noloco/core/src/utils/hooks/usePromiseQuery';
import { getText } from '@noloco/core/src/utils/lang';
import { forEachPromise } from '@noloco/core/src/utils/promises';
import { toInput } from '@noloco/core/src/utils/recommendations';
import {
  CREATE_RECOMMENDED_DATA_TYPES,
  RECOMMEND_DATA_TYPES,
  SUGGEST_LAYOUT,
} from '../queries/project';
import { useMutateProjectElements } from '../utils/hooks/projectHooks';
import { generateViewFromDataSource } from '../utils/layout';
import ManualCollectionBuilderLink from './ManualCollectionBuilderLink';
import RecommendedDataType, {
  RecommendedDataTypeModel,
} from './RecommendedDataType';
import VisualizeDataTables from './visualize/VisualizeDataTables';

const LANG_KEY = 'newProject.recommendations';

const MIN_LENGTH = 50;
const MAX_LENGTH = 500;

const NUM_LOADING_MESSAGES = 14;
const LOADING_MESSAGE_TIMEOUT = 5000;

const getRandomLoadingMessageIdx = () =>
  Math.floor(Math.random() * NUM_LOADING_MESSAGES);

const formatDataTypes = (
  dataTypes: Omit<
    RecommendedDataTypeModel & {
      fields: Omit<DataField, 'apiName' | 'reverseName'>[];
    },
    'apiName'
  >[],
): DataTypeArray<RecommendedDataTypeModel> =>
  getDataTypesWithRelations(
    dataTypes.map((dataType, index) => ({
      ...dataType,
      id: 1000 + index,
      apiName: dataType.name,
      fields: dataType.fields.map((field, fieldIndex) => ({
        ...field,
        id: 100 * index + fieldIndex,
        apiName: field.name,
        options: field.options
          ? new DataFieldOptions(field.options)
          : undefined,
        reverseName: field.reverseDisplayName,
      })),
    })),
  );

const AICollectionBuilder = ({
  defaultAppDescription,
  handleNextOrSubmit,
  onSwitchToManual,
  project,
}: any) => {
  const [getRecommendedDataTypes] = usePromiseQuery(RECOMMEND_DATA_TYPES, {
    fetchPolicy: 'no-cache',
    context: { skipLambdas: true },
  });
  const [createRecommendedDataTypes, { loading: isCreating }] = useMutation(
    CREATE_RECOMMENDED_DATA_TYPES,
  );
  const intervalRef = useRef<number>();
  const [prompt, setPrompt] = useState(defaultAppDescription ?? '');
  const [loadingMessageIdx, setLoadingMessageIdx] = useState(
    getRandomLoadingMessageIdx(),
  );
  const [hasSubmit, setHasSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBuilding, setIsBuilding] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [draftDataTypes, setDraftDataTypes] = useState<
    RecommendedDataTypeModel[] | null
  >(null);
  const [visualize, setVisualize] = useState(false);
  const [suggestLayout] = usePromiseQuery(SUGGEST_LAYOUT);
  const [updateElements]: any = useMutateProjectElements();

  const errorAlert = useGraphQlErrorAlert();

  const dataTypes = useMemo(
    () =>
      draftDataTypes
        ? formatDataTypes(
            draftDataTypes as Omit<
              RecommendedDataTypeModel & {
                fields: Omit<DataField, 'apiName' | 'reverseName'>[];
              },
              'apiName'
            >[],
          )
        : null,
    [draftDataTypes],
  );

  useEffect(() => {
    if (isCreating || isLoading) {
      intervalRef.current = window.setInterval(() => {
        setLoadingMessageIdx(() => getRandomLoadingMessageIdx());
      }, LOADING_MESSAGE_TIMEOUT);

      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [isCreating, isLoading]);

  const onRecommendDataTypes = useCallback(() => {
    if (prompt.length >= MIN_LENGTH && prompt.length < MAX_LENGTH) {
      setHasSubmit(true);
      setIsLoading(true);
      getRecommendedDataTypes({
        variables: {
          projectName: project.name,
          prompt,
        },
      })
        .then(({ data }) => {
          setDraftDataTypes(data.recommendDataTypes.dataTypes);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          errorAlert(getText(LANG_KEY, 'prompt.error'), e);
          setIsLoading(false);
        });
    }
  }, [errorAlert, getRecommendedDataTypes, project, prompt]);

  const onFetchRecordForDataType = useCallback(
    (dataType: RecommendedDataTypeModel) => {
      const recommendedDataType = dataTypes?.find(
        ({ name }) => name === dataType.name,
      );
      if (!recommendedDataType) {
        return Promise.resolve({} as BaseRecord);
      }

      return Promise.resolve(recommendedDataType.sample ?? null);
    },
    [dataTypes],
  );

  const updateDataType = useCallback(
    (index: number) => (dataType: RecommendedDataTypeModel) =>
      setDraftDataTypes(
        draftDataTypes
          ? draftDataTypes.map((draftDataType, idx) =>
              index === idx
                ? ({
                    ...dataType,
                    fields: dataType.fields.filter(
                      ({ relatedField }) => !relatedField,
                    ),
                  } as RecommendedDataTypeModel)
                : draftDataType,
            )
          : null,
      ),
    [draftDataTypes, setDraftDataTypes],
  );

  const onSubmit = useCallback(
    (event: SyntheticEvent) => {
      setIsSubmitting(true);

      createRecommendedDataTypes({
        variables: {
          projectName: project.name,
          recommendedDataTypes: draftDataTypes!.map(toInput),
        },
      })
        .then(({ data }) => {
          setIsBuilding(true);

          return forEachPromise(
            new DataTypes(
              data.createRecommendedDataTypes.dataTypes.filter(
                ({ internal, name }: DataType) =>
                  !internal && !builtInDataTypes.includes(name),
              ),
            ),
            async (dataType: DataType) => {
              let layout = TABLE;
              let layoutConfig = {};

              try {
                const { data } = await suggestLayout({
                  variables: {
                    projectName: project.name,
                    dataTypeId: dataType.id,
                  },
                });

                if (data?.suggestLayout?.layout) {
                  layout = data?.suggestLayout?.layout;
                  layoutConfig = data?.suggestLayout;
                }
              } catch (error) {
                console.error(
                  'Error suggesting layout for',
                  dataType.id,
                  error,
                );
              }

              return generateViewFromDataSource(
                dataType.display,
                dataType,
                project,
                {
                  userGenerated: false,
                  createdAt: new Date(),
                },
                {
                  userGenerated: false,
                  createdAt: new Date(),
                },
                layout,
                false,
                layoutConfig,
              );
            },
          );
        })
        .then((newViews) => {
          updateElements([], newViews, project.name);
          setIsBuilding(false);
          setIsSubmitting(false);
          handleNextOrSubmit(event);
        });
    },
    [
      createRecommendedDataTypes,
      draftDataTypes,
      handleNextOrSubmit,
      project,
      suggestLayout,
      updateElements,
    ],
  );

  const onTryAgain = useCallback(() => {
    setHasSubmit(false);
    setDraftDataTypes(null);
  }, []);

  const promptInput = useMemo(
    () => (
      <div className="relative mt-2 rounded-lg border bg-white p-3">
        <AutoSizedTextInput
          className="mb-10 text-base"
          disabled={hasSubmit || isLoading}
          placeholder={getText(LANG_KEY, 'prompt.placeholder')}
          rows={4}
          size={LG}
          value={prompt}
          type="textarea"
          minHeight={100}
          onChange={({ target: { value } }: any) => setPrompt(value)}
          surface={LIGHT}
        />
        <Button
          className="absolute bottom-4 right-4 flex items-center"
          disabled={
            prompt.length < MIN_LENGTH ||
            prompt.length > MAX_LENGTH ||
            isLoading ||
            isCreating ||
            isBuilding
          }
          variant={hasSubmit ? 'secondary' : undefined}
          onClick={!hasSubmit ? onRecommendDataTypes : onTryAgain}
        >
          {!hasSubmit ? (
            <IconWand size={22} className="mr-4 opacity-75" />
          ) : (
            <IconPencil size={22} className="mr-4 opacity-75" />
          )}
          <span>{getText(LANG_KEY, !hasSubmit ? 'cta' : 'tryAgain')}</span>
        </Button>
      </div>
    ),
    [
      onTryAgain,
      hasSubmit,
      isBuilding,
      isCreating,
      isLoading,
      onRecommendDataTypes,
      prompt,
    ],
  );

  if (isLoading || isCreating || isBuilding) {
    return (
      <div className="flex max-w-screen-xl flex-grow flex-col overflow-hidden">
        {promptInput}
        <div className="mt-16 flex w-full flex-col items-center justify-center">
          <Loader size={LG} />
          <span className="mt-6 max-w-sm text-center italic text-gray-600">
            {isLoading && getText(LANG_KEY, 'loading', loadingMessageIdx)}
            {isCreating && getText(LANG_KEY, 'creating', loadingMessageIdx)}
            {isBuilding && getText(LANG_KEY, 'building')}
          </span>
        </div>
        <ManualCollectionBuilderLink onSwitchToManual={onSwitchToManual} />
      </div>
    );
  }

  if (hasSubmit) {
    return (
      <div className="flex max-w-screen-xl flex-grow flex-col overflow-hidden">
        {promptInput}
        {draftDataTypes && get(draftDataTypes, ['length'], 0) > 0 && (
          <div className="mt-8 h-full overflow-hidden">
            <div className="flex flex-row">
              <h2 className="text-md">
                {getText(
                  { count: draftDataTypes.length },
                  LANG_KEY,
                  'dataTypes.title',
                )}
              </h2>
              <div className="ml-auto flex">
                <div
                  className={classNames(
                    'w-32 rounded-bl-md rounded-tl-md border p-1 text-center',
                    {
                      'border-pink-200 bg-pink-200': !visualize,
                      'cursor-pointer bg-white hover:bg-gray-200': visualize,
                    },
                  )}
                  onClick={() => setVisualize(false)}
                >
                  {getText(LANG_KEY, 'dataTypes.visualize.off')}
                </div>
                <div
                  className={classNames(
                    '-ml-1 w-32 rounded-br-md rounded-tr-md border p-1 text-center',
                    {
                      'cursor-pointer bg-white hover:bg-gray-200': !visualize,
                      'border-pink-200 bg-pink-200': visualize,
                    },
                  )}
                  onClick={() => setVisualize(true)}
                >
                  {getText(LANG_KEY, 'dataTypes.visualize.on')}
                </div>
                <Button
                  className="ml-4"
                  disabled={isSubmitting}
                  onClick={onSubmit}
                >
                  {getText(LANG_KEY, 'submit')}
                </Button>
              </div>
            </div>
            {!visualize && (
              <div className="mt-8 flex max-h-full flex-grow flex-row flex-wrap overflow-y-auto pb-16">
                {dataTypes &&
                  dataTypes.map((dataType, index) => (
                    <RecommendedDataType
                      dataType={dataType}
                      dataTypes={dataTypes}
                      key={dataType.name}
                      updateDataType={updateDataType(index)}
                    />
                  ))}
              </div>
            )}
            {visualize && (
              <VisualizeDataTables
                className="mt-6 rounded-lg"
                dataTypes={dataTypes ?? []}
                getRecordForDataType={onFetchRecordForDataType}
              />
            )}
          </div>
        )}
        {get(draftDataTypes, ['length'], 0) === 0 && (
          <div className="mt-8 h-full">
            <div className="flex flex-row">
              <h2 className="text-md">{getText(LANG_KEY, 'error.title')}</h2>
              <div className="ml-auto flex">
                <Button
                  className="ml-4 whitespace-nowrap"
                  onClick={onSwitchToManual}
                >
                  {getText(LANG_KEY, 'error.cta')}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="max-w-screen-xl overflow-hidden">
      <div className="mb-6 flex flex-col">
        <h1 className="mb-1 flex text-lg text-gray-700">
          {getText(LANG_KEY, 'prompt.title')}
          <Tooltip
            content={
              <div className="space-y-1">
                <p>{getText(LANG_KEY, 'prompt.tooltip.beta')}</p>
                <p>{getText(LANG_KEY, 'prompt.tooltip.feedback')}</p>
              </div>
            }
          >
            <Badge m={{ r: 0, l: 2 }} color="pink" ignoreDarkMode={true}>
              {getText(LANG_KEY, 'beta')}
            </Badge>
          </Tooltip>
        </h1>
        <p className="mb-3 text-sm text-gray-500">
          {getText(LANG_KEY, 'prompt.subtitle')}
        </p>
      </div>
      {promptInput}
      <div className="mt-1 flex items-center">
        <div className="flex h-2 w-full items-center rounded-full bg-gray-200">
          <div
            className="h-2 rounded-full bg-pink-500"
            style={{
              width: `${
                (Math.min(prompt.length, MIN_LENGTH) / MIN_LENGTH) * 100
              }%`,
            }}
          />
        </div>
        <span className="ml-4 whitespace-nowrap text-sm text-gray-400">
          {MIN_LENGTH > prompt.length ? (
            getText(
              { context: MIN_LENGTH - prompt.length },
              LANG_KEY,
              'prompt.moreCharacters',
            )
          ) : prompt.length <= MAX_LENGTH ? (
            <IconCircleCheck className="text-green-600" size={24} />
          ) : (
            <span className="flex items-center">
              <span className="mr-2 text-red-500">
                {MAX_LENGTH - prompt.length}
              </span>
              <IconAlertCircleFilled className="text-red-600" size={20} />
            </span>
          )}
        </span>
      </div>
      <ManualCollectionBuilderLink onSwitchToManual={onSwitchToManual} />
    </div>
  );
};

export default AICollectionBuilder;
