import React, { useState } from 'react';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import Usage from './Usage';

const ExpandableUsage = ({ children, filterFeatures, usage }: any) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <div
        className="mb-4 mt-8 flex cursor-pointer space-x-4"
        onClick={() => setIsOpen((currentValue) => !currentValue)}
      >
        {isOpen ? (
          <IconChevronDown size={28} />
        ) : (
          <IconChevronRight size={28} />
        )}
        {children}
      </div>
      {isOpen && <Usage filterFeatures={filterFeatures} usage={usage} />}
    </>
  );
};

export default ExpandableUsage;
