import gql from 'graphql-tag';

export const ONBOARDING_MUTATION = gql`
  mutation onboarding($onboardingDetails: JSON) {
    onboarding(onboardingDetails: $onboardingDetails) {
      id
      email
      onboardingDate
      onboardingCompleted
    }
  }
`;
