import { useSelector } from 'react-redux';
import { billingPlansSelector } from '../../selectors/billingPlanSelectors';
import { isTrailingPlanExpired } from '../billing';

/**
 * Custom hook to determine if there is a workspace that is not trialing AND expired.
 *
 * @returns {boolean} True if there is at least workspace not trialing or expired false otherwise.
 */
export const useHasActiveWorkspace = () => {
  const plans = useSelector(billingPlansSelector);

  return plans ? plans.some((plan) => !isTrailingPlanExpired(plan)) : false;
};
