import gql from 'graphql-tag';
import { teamFragment } from '@noloco/core/src/queries/auth';

export const ADD_TEAM = gql`
    mutation addTeam($name: String!) {
        addTeam(name: $name) {
            ${teamFragment}
        }
    }
`;

export const UPDATE_TEAM = gql`
    mutation updateTeam($id: Int!, $name: String!) {
        updateTeam(id: $id, name: $name) {
            ${teamFragment}
        }
    }
`;
