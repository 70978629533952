import {
  IconCreditCard,
  IconLifebuoy,
  IconUserCircle,
  IconUsersGroup,
} from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { Popover } from '@noloco/components';
import Avatar from '@noloco/core/src/components/Avatar';
import { COMMUNITY_URL } from '@noloco/core/src/constants/urls';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import infinityLogoWhite from '../../img/infinity-logo-white.svg';
import DashboardNavItem from './DashboardNavItem';

const DashboardNav = () => {
  const { signOut, user } = useDashboardAuth();
  const { push } = useRouter();

  const handleSignOut = () => {
    signOut();
    push('/login');
  };

  return (
    <div className="bg-brand-darkest w-full bg-opacity-50">
      <div className="flex h-20 w-full px-8 py-4 text-lg text-white">
        <Link to="">
          <img src={infinityLogoWhite} className="h-full" alt="logo" />
        </Link>
        <div className="ml-8 flex items-center">
          <DashboardNavItem to="https://guides.noloco.io/" external={true}>
            <IconLifebuoy className="mr-2 opacity-75" />
            <span>{getText('dashboard.nav.guides')}</span>
          </DashboardNavItem>
          <DashboardNavItem to={COMMUNITY_URL} external={true}>
            <IconUsersGroup className="mr-2 opacity-75" />
            <span>{getText('dashboard.nav.community')}</span>
          </DashboardNavItem>
          <DashboardNavItem to="/billing">
            <IconCreditCard className="mr-2 opacity-75" />
            <span>{getText('dashboard.nav.billing')}</span>
          </DashboardNavItem>
          <DashboardNavItem to="/profile">
            <IconUserCircle className="mr-2 opacity-75" />
            <span>{getText('dashboard.nav.profile')}</span>
          </DashboardNavItem>
        </div>
        {user && (
          <div className="ml-auto">
            <Popover
              closeOnClick={true}
              content={
                <div className="flex w-72 flex-col text-left">
                  <div className="flex flex-col border-b border-gray-200 p-6">
                    <span className="text-xl font-medium">
                      {user.firstName} {user.lastName}
                    </span>
                    <span className="text-gray-700">{user.email}</span>
                  </div>
                  <Link
                    className="border-b border-gray-200 px-6 py-4 text-left hover:bg-gray-100"
                    to="/refer"
                  >
                    {getText('dashboard.nav.refer')}
                  </Link>
                  <Link
                    className="border-b border-gray-200 px-6 py-4 text-left hover:bg-gray-100"
                    to="/billing"
                  >
                    {getText('dashboard.nav.billing')}
                  </Link>
                  <button
                    className="px-6 py-4 text-left hover:bg-gray-100"
                    onClick={handleSignOut}
                  >
                    {getText('dashboard.nav.signOut')}
                  </button>
                </div>
              }
              className="overflow-hidden bg-white"
              p={0}
              rounded="lg"
              placement="bottom-end"
              shadow="lg"
              showArrow={false}
            >
              <div>
                <Avatar className="cursor-pointer" size={12} user={user} />
              </div>
            </Popover>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardNav;
