import { configureStore } from '@reduxjs/toolkit';
import { IS_PRODUCTION } from '../constants/env';
import rootReducer from '../reducers';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  devTools: !IS_PRODUCTION,
});

if (typeof window !== 'undefined') {
  window.__REDUX_STORE__ = store;
}

export default store;
