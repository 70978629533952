import React, { useCallback, useEffect, useState } from 'react';
import useDrivePicker from '@fyelci/react-google-drive-picker';
import { Button } from '@noloco/components/src';
import {
  GOOGLE_CLIENT_ID,
  GOOGLE_PROJECT_ID,
} from '@noloco/core/src/constants/auth';
import { GOOGLE_SHEETS_SYNCING_SCOPES } from '@noloco/core/src/constants/oauth';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'data.googleSheets';

// This is required if we want to authenticate the current user with Google
const GOOGLE_PICKER_API_KEY =
  process.env.REACT_APP_GOOGLE_PICKER_API_KEY ??
  'AIzaSyD7x7ERAMChhPDEqAXvLOl3beFSXWS7u4g';

const GSheetPicker = () => {
  const [openPicker] = useDrivePicker();
  const [rendered, setRendered] = useState(false);

  const handleOpenPicker = useCallback(() => {
    openPicker({
      appId: GOOGLE_PROJECT_ID,
      clientId: GOOGLE_CLIENT_ID,
      developerKey: GOOGLE_PICKER_API_KEY,
      customScopes: GOOGLE_SHEETS_SYNCING_SCOPES, // The picker defaults to the restricted scope for full 'drive' access
      viewId: 'SPREADSHEETS',
      callbackFunction: (pickerResult) => {
        switch (pickerResult.action) {
          case 'picked': {
            const doc = pickerResult.docs[0];
            window.opener.postMessage(
              {
                type: 'googleSheetPicked',
                sheetId: doc.id,
                sheetName: doc.name,
              },
              '*',
            );
            window.close();
            break;
          }
          case 'cancel':
            window.close();
            break;
        }
      },
    });
    setRendered(true);
  }, [openPicker, setRendered]);

  useEffect(() => {
    if (!rendered) {
      const timer = setTimeout(handleOpenPicker, 100);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [rendered, handleOpenPicker]);

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center bg-slate-100">
      <p className="max-w-1/2 text-center text-2xl text-gray-900">
        {getText(LANG_KEY, 'pickSheet.popup.title')}
      </p>
      <p className="max-w-1/2 text-md mt-4 text-center text-gray-800">
        {getText(LANG_KEY, 'pickSheet.popup.subtitle')}
      </p>
      <p className="max-w-1/2 mt-4 text-center text-sm text-gray-700">
        {getText(LANG_KEY, 'pickSheet.popup.note')}
      </p>

      <Button onClick={handleOpenPicker} className="mt-6 rounded px-4 py-2">
        {getText(LANG_KEY, 'pickSheet.popup.authenticateButtonText')}
      </Button>
      <p className="max-w-1/2 mt-4 text-center text-sm text-gray-600">
        {getText(LANG_KEY, 'pickSheet.popup.manualOpenInstructions')}
      </p>
    </div>
  );
};

export default GSheetPicker;
