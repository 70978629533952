import React from 'react';

const OnboardingProgressBar = ({
  value,
  max,
}: {
  value: number;
  max: number;
}) => (
  <div className="fixed left-0 top-0 w-full bg-pink-200">
    <div
      className="h-1.5 w-full rounded-br-lg rounded-tr-lg bg-pink-500 transition-all duration-200"
      style={{
        width: `${(value / max) * 100}%`,
      }}
    />
  </div>
);

export default OnboardingProgressBar;
