import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import {
  AJS_ANONYMOUS_ID,
  AUTH_TOKEN,
  CLIENT_AUTH_TOKEN,
} from '@noloco/core/src/constants/auth';
import SafeStorage from '@noloco/core/src/utils/SafeStorage';
import { getOperationUri } from '@noloco/core/src/utils/graphQlEndpoints';

const getAuthToken = () => {
  const token = new SafeStorage().get(AUTH_TOKEN);
  return token ? `Bearer ${token}` : '';
};

const getAnalyticsToken = () => {
  const token = new SafeStorage().get(AJS_ANONYMOUS_ID);
  return token ? String(token).replace(/"/g, '') : null;
};

const getClientAuthToken = () => {
  const token = new SafeStorage().get(CLIENT_AUTH_TOKEN);
  return token ? `Bearer ${token}` : '';
};

const httpLink = createUploadLink({
  uri: getOperationUri(),
});

const authLink = setContext(
  (__, { headers, projectQuery, withCoreAuth, ...rest }) => ({
    ...rest,
    headers: {
      ...headers,
      authorization:
        projectQuery && !withCoreAuth ? getClientAuthToken() : getAuthToken(),
      'x-ajs-id': getAnalyticsToken(),
    },
  }),
);

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
