import React, { useState } from 'react';
import {
  Template,
  TemplateProject as TemplateProjectModel,
} from '@noloco/core/src/models/Template';
import { getText } from '@noloco/core/src/utils/lang';
import CloneTemplateModal from './CloneTemplateModal';
import TemplateProject from './TemplateProject';

const TemplateList = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null,
  );
  const [selectedTemplateProject, setSelectedTemplateProject] =
    useState<TemplateProjectModel | null>(null);

  return (
    <div className="mt-8 flex flex-col">
      <h1 className="mb-1 text-2xl font-medium">
        {getText('templateList.title')}
      </h1>
      <p className="tex-gray-700 mb-4 text-sm">
        {getText('templateList.subtitle')}
      </p>
      <TemplateProject
        selectTemplate={(template, templateProject) => {
          setSelectedTemplate(template);
          setSelectedTemplateProject(templateProject);
        }}
      />
      {selectedTemplate && selectedTemplateProject && (
        <CloneTemplateModal
          onClose={() => {
            setSelectedTemplate(null);
            setSelectedTemplateProject(null);
          }}
          setSelectedTemplate={setSelectedTemplate}
          setSelectedTemplateProject={setSelectedTemplateProject}
          template={selectedTemplate}
          templateProject={selectedTemplateProject}
        />
      )}
    </div>
  );
};

export default TemplateList;
