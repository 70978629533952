import React, { useCallback, useMemo } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CardElement } from '@stripe/react-stripe-js';
import { IconPlus } from '@tabler/icons-react';
import classNames from 'classnames';
import { Badge } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import useLocale from '@noloco/components/src/utils/hooks/useLocale';
import {
  MONTH,
  PlanInterval,
  YEAR,
} from '@noloco/core/src/constants/accountPlanIntervals';
import {
  EXTERNAL_USER_ADDON_ACTIVATION_PRICES,
  PLAN_PRICES,
} from '@noloco/core/src/constants/accountPlanPrices';
import {
  CUSTOMER_PORTAL,
  INTERNAL_TOOL,
  PlanProduct,
} from '@noloco/core/src/constants/accountPlanProducts';
import { SEAT_BASED_PLANS } from '@noloco/core/src/constants/accountPlanTypes';
import { AccountPlan } from '@noloco/core/src/constants/accountPlans';
import { BillingPlan } from '@noloco/core/src/models/BillingPlan';
import { ProjectsWithUsage } from '@noloco/core/src/models/Usage';
import { getText } from '@noloco/core/src/utils/lang';
import ExternalUsersAddonConfiguration from '../../../settings/ExternalUsersAddonConfiguration';
import PlanRadioOption from '../../PlanRadioOption';
import PlanConfigurationSection from './PlanConfigurationSection';
import ProjectProduct from './ProjectProduct';
import PromotionCode from './PromotionCode';

type Props = {
  billingPlan: BillingPlan;
  className?: string;
  hasPaymentMethod: boolean;
  internalDomains: string[];
  interval: PlanInterval;
  onApplyPromotionCode: (promotionCode: string | null) => void;
  onChangeInternalDomains: (internalDomains: string[]) => void;
  onChangeInterval: (interval: PlanInterval) => void;
  onChangeProduct: (project: string) => (product: PlanProduct) => void;
  payKey: string;
  projects: ProjectsWithUsage;
  promotionCodeValid: boolean | null;
  recalculating: boolean;
  selectedPlan: AccountPlan;
};

const PlanConfiguration = ({
  billingPlan,
  className,
  hasPaymentMethod,
  internalDomains,
  interval,
  onApplyPromotionCode,
  onChangeInternalDomains,
  onChangeInterval,
  onChangeProduct,
  payKey,
  projects,
  promotionCodeValid,
  recalculating,
  selectedPlan,
}: Props) => {
  const isFlatPlan = !SEAT_BASED_PLANS.includes(selectedPlan);
  const locale = useLocale();

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat(locale.code, {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'narrowSymbol',
      }),
    [locale],
  );

  const customerPortalActivationCost =
    EXTERNAL_USER_ADDON_ACTIVATION_PRICES[selectedPlan];

  const onAddDomain = useCallback(
    (e) => {
      e.preventDefault();
      onChangeInternalDomains([...internalDomains, '']);
    },
    [internalDomains, onChangeInternalDomains],
  );
  const onUpdateDomain = useCallback(
    (index: number, value: string) =>
      onChangeInternalDomains(
        internalDomains.map((domainValue, domainIndex) =>
          index === domainIndex ? value : domainValue,
        ),
      ),
    [internalDomains, onChangeInternalDomains],
  );
  const onRemoveDomain = useCallback(
    (index: number) => () =>
      onChangeInternalDomains(
        internalDomains.filter((_, domainIndex) => domainIndex !== index),
      ),
    [internalDomains, onChangeInternalDomains],
  );

  return (
    <div className={classNames('flex flex-col space-y-8', className)}>
      <PlanConfigurationSection title={getText('billing.changePlan.interval')}>
        <RadioGroup
          className="flex items-center space-x-4"
          disabled={recalculating}
          value={interval}
          onChange={onChangeInterval}
        >
          <PlanRadioOption
            active={interval === YEAR}
            badge={
              <Badge className="ml-4" color="pink">
                {getText('billing.plan.interval', YEAR, 'save')}
              </Badge>
            }
            subtitle={getText(
              {
                amount: currencyFormatter.format(
                  PLAN_PRICES[YEAR][selectedPlan],
                ),
              },
              'billing.plan.interval',
              isFlatPlan ? 'perMonth' : 'perActiveUserPerMonth',
            )}
            title={getText('billing.plan.interval', YEAR, 'pay')}
            value={YEAR}
          />
          <PlanRadioOption
            active={interval === MONTH}
            subtitle={getText(
              {
                amount: currencyFormatter.format(
                  PLAN_PRICES[MONTH][selectedPlan],
                ),
              },
              'billing.plan.interval',
              isFlatPlan ? 'perMonth' : 'perActiveUserPerMonth',
            )}
            title={getText('billing.plan.interval', MONTH, 'pay')}
            value={MONTH}
          />
        </RadioGroup>
      </PlanConfigurationSection>
      {!isFlatPlan && <hr />}
      {!isFlatPlan && (
        <PlanConfigurationSection title={getText('billing.changePlan.apps')}>
          <div className="space-y-2 rounded-lg bg-white p-4 shadow">
            {projects.map(({ name, addons }) => (
              <ProjectProduct
                customerPortalActivationCost={customerPortalActivationCost}
                disabled={recalculating}
                key={name}
                onSelectProduct={onChangeProduct}
                product={addons.externalUsers ? CUSTOMER_PORTAL : INTERNAL_TOOL}
                projectName={name}
              />
            ))}
          </div>
        </PlanConfigurationSection>
      )}
      {!isFlatPlan && <hr />}
      {!isFlatPlan && projects.some(({ addons }) => addons.externalUsers) && (
        <>
          <PlanConfigurationSection
            button={
              <button
                className="ml-auto p-2 text-gray-700 hover:text-black"
                onClick={onAddDomain}
              >
                <IconPlus size={16} />
              </button>
            }
            title={getText('billing.changePlan.domains')}
          >
            <ExternalUsersAddonConfiguration
              disabled={recalculating}
              domains={internalDomains}
              onRemoveDomain={onRemoveDomain}
              onUpdateDomain={onUpdateDomain}
              surface={LIGHT}
            />
          </PlanConfigurationSection>
          <hr />
        </>
      )}
      <PlanConfigurationSection
        title={getText('billing.changePlan.paymentDetails')}
      >
        {!hasPaymentMethod && (
          <PromotionCode
            disabled={recalculating}
            onApplyPromotionCode={onApplyPromotionCode}
            valid={promotionCodeValid}
          />
        )}
        {!hasPaymentMethod && (
          <CardElement
            id="card-details"
            className="rounded-lg border bg-white px-6 py-4 text-lg"
          />
        )}
        {hasPaymentMethod && selectedPlan !== billingPlan.type && (
          <p className="mb-1 text-base font-medium">
            {getText('billing.changePlan', payKey, 'explain')}
          </p>
        )}
      </PlanConfigurationSection>
    </div>
  );
};

export default PlanConfiguration;
