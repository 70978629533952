import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import Avatar from '@noloco/core/src/components/Avatar';
import BaseDropzone from '@noloco/core/src/components/dropzone/BaseDropzone';
import { IMAGE_MIMETYPES } from '@noloco/core/src/constants/mimetypes';
import { setUser } from '@noloco/core/src/reducers/user';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import { getText } from '@noloco/core/src/utils/lang';
import { UPLOAD_USER_PROFILE_PICTURE } from '../queries/project';

const Profile = () => {
  const { user } = useDashboardAuth();
  const dispatch = useDispatch();
  const [uploadProfilePicture] = useMutation(UPLOAD_USER_PROFILE_PICTURE);
  const [picturePreviewUrl, setPicturePreviewUrl] = useState<string | null>(
    get(user, 'profilePicture.uri', null),
  );
  const [uploading, setUploading] = useState<boolean>(false);

  const onHandleFileSelect = useCallback(
    (fileObject: any) => {
      if (!fileObject) {
        return null;
      }

      setUploading(true);
      const [file, _, previewUrl] = fileObject;
      setPicturePreviewUrl(previewUrl);

      uploadProfilePicture({ variables: { file } })
        .then((resultData) => {
          dispatch(
            setUser({
              ...user,
              profilePicture: resultData.data.uploadProfilePicture,
            }),
          );
        })
        .catch((error) => {
          console.log('Error', error);
        })
        .finally(() => setUploading(false));
    },
    [uploadProfilePicture, dispatch, user],
  );

  return (
    <div className="flex flex-col space-y-4 p-8 text-lg">
      <h1 className="text-2xl font-medium">{getText('profile.title')}</h1>
      <hr className="bg-slate-100" />
      {user && (
        <>
          <div className="grid grid-cols-8">
            <h3 className="col-span-2 text-slate-500">
              {getText('profile.fullName')}
            </h3>
            <h3 className="col-span-6">{`${user.firstName} ${user.lastName}`}</h3>
          </div>
          <div className="grid grid-cols-8">
            <h3 className="col-span-2 text-slate-500">
              {getText('profile.email')}
            </h3>
            <h3 className="col-span-6">{user.email}</h3>
          </div>
          <div className="grid grid-cols-8">
            <h3 className="col-span-2 text-slate-500">
              {getText('profile.profilePicture')}
            </h3>
            <BaseDropzone
              acceptedMimetypes={IMAGE_MIMETYPES}
              className="focus-within:ring-0"
              disabled={uploading}
              maxFiles={1}
              onChange={onHandleFileSelect}
            >
              {() =>
                picturePreviewUrl ? (
                  <img
                    alt="profile"
                    className={classNames(
                      'h-12 w-12 rounded-full ring-slate-600',
                      {
                        'animate-pulse cursor-not-allowed': uploading,
                        'hover:ring-2': !uploading,
                      },
                    )}
                    src={picturePreviewUrl}
                  />
                ) : (
                  <Avatar
                    className={classNames(
                      'cursor-pointer ring-slate-500 hover:ring-2',
                      {
                        'animate-pulse cursor-not-allowed': uploading,
                        'hover:ring-2': !uploading,
                      },
                    )}
                    size={12}
                    user={user}
                  />
                )
              }
            </BaseDropzone>
          </div>
        </>
      )}
    </div>
  );
};

export default Profile;
