import React, { useMemo, useState } from 'react';
import get from 'lodash/get';
import {
  Template,
  TemplateProject as TemplateProjectModel,
} from '@noloco/core/src/models/Template';
import useCacheQuery from '@noloco/core/src/utils/hooks/useCacheQuery';
import { TEMPLATES } from '../queries/project';
import Templates from './Templates';
import TemplatesModal from './TemplatesModal';

const TemplateProject = ({
  selectTemplate,
}: {
  selectTemplate: (
    template: Template,
    templateProject: TemplateProjectModel,
  ) => void;
}) => {
  const { loading: templatesLoading, data } = useCacheQuery(TEMPLATES);
  const templates = useMemo(() => get(data, 'templates.data', []), [data]);
  const [activeTemplate, setActiveTemplate] = useState<Template | null>(null);

  return (
    <div>
      <div className="grid grid-cols-5 gap-4 pb-12 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
        <Templates
          templates={templates}
          templatesLoading={templatesLoading}
          setActiveTemplate={setActiveTemplate}
        />
      </div>
      {activeTemplate && (
        <TemplatesModal
          selectTemplate={selectTemplate}
          onClose={() => setActiveTemplate(null)}
          template={activeTemplate}
        />
      )}
    </div>
  );
};

export default TemplateProject;
