import React, { useMemo } from 'react';
import { LIGHT } from '@noloco/components/src/constants/surface';
import {
  AIRTABLE,
  GOOGLE_SHEETS,
  MYSQL,
  POSTGRES,
  SMART_SUITE,
  XANO,
} from '@noloco/core/src/constants/dataSources';
import { ProvideProjectUpdates } from '../utils/hooks/projectHooks';
import AddAirtableBase from './dataTable/AddAirtableBase';
import AddGoogleSheet from './dataTable/AddGoogleSheet';
import AddSmartSuiteSolution from './dataTable/AddSmartSuiteSolution';
import AddSqlDatabase from './dataTable/AddSqlDatabase';
import AddXanoWorkspace from './dataTable/AddXanoWorkspace';

const DataSourceComponent = {
  [AIRTABLE]: AddAirtableBase,
  [POSTGRES]: AddSqlDatabase,
  [MYSQL]: AddSqlDatabase,
  [GOOGLE_SHEETS]: AddGoogleSheet,
  [SMART_SUITE]: AddSmartSuiteSolution,
  [XANO]: AddXanoWorkspace,
};
const ProjectConnection = ({
  project,
  dataSource,
  handleNextOrSubmit,
}: any) => {
  const AddDataSource = useMemo(
    () => DataSourceComponent[dataSource],
    [dataSource],
  );

  return (
    project && (
      <div className="w-full overflow-auto">
        <ProvideProjectUpdates projectName={project.name}>
          <hr className="mb-6 mt-3 bg-gray-100" />
          <AddDataSource
            inOnboarding={true}
            project={project}
            onConnect={handleNextOrSubmit}
            sqlType={dataSource}
            surface={LIGHT}
          />
        </ProvideProjectUpdates>
      </div>
    )
  );
};

export default ProjectConnection;
