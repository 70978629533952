import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Loader } from '@noloco/components';
import { DEMO_USER_TOKEN_QUERY_STRING } from '@noloco/core/src/queries/project';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { getProjectDomain } from '@noloco/core/src/utils/pages';
import useTrackDashboardPage, {
  PageTypes,
} from '../utils/hooks/useTrackDashboardPage';

const ProjectDemo = () => {
  const {
    pathname,
    query: { projectId },
  } = useRouter();
  useTrackDashboardPage(PageTypes.TEMPLATE_REDIRECT, { projectId });

  const { error, data } = useQuery(
    gql`
      ${DEMO_USER_TOKEN_QUERY_STRING}
    `,
    {
      context: {
        projectQuery: true,
        withCoreAuth: true,
        projectName: projectId,
      },
    },
  );

  const projectDomain = getProjectDomain(projectId);

  const replaceRegex = new RegExp(`^/t/${projectId}`);
  const newPathname = pathname.replace(replaceRegex, '');

  const token = get(data, ['demoUser', 'token']);

  useEffect(() => {
    if (token) {
      document.location = `${projectDomain}${newPathname}?authToken=${token}`;
    }
  }, [newPathname, projectDomain, token]);

  if (error) {
    return (
      <div className="flex h-screen w-screen items-center justify-center bg-slate-800 text-white">
        <p className="max-w-md text-center">
          {getText('project.errors.generic')}
        </p>
      </div>
    );
  }

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-slate-800 text-white">
      <Loader size="lg" className="text-blue-400" />
    </div>
  );
};

export default ProjectDemo;
