import React from 'react';
import { Redirect } from 'react-router';
import { Loader } from '@noloco/components';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import NewProject from './NewProject';

const NewProjectWrapper = () => {
  const { user, fetchedUser } = useDashboardAuth();

  if (fetchedUser && !user) {
    return <Redirect to="/login" />;
  }

  if (!user) {
    return <Loader className="mx-auto mt-32 text-white" />;
  }

  return <NewProject />;
};

export default NewProjectWrapper;
