import React from 'react';
import { DateTime } from 'luxon';
import { Tooltip } from '@noloco/components';
import Avatar from '@noloco/core/src/components/Avatar';
import { BaseUser } from '@noloco/core/src/models/User';
import { getText } from '@noloco/core/src/utils/lang';
import { getFullName } from '@noloco/core/src/utils/user';

const getRelativeInviteDate = (createdAt: string) => {
  const date = DateTime.fromISO(createdAt);
  return date.toRelativeCalendar();
};

const getAbsoluteInviteDate = (createdAt: string) => {
  const date = DateTime.fromISO(createdAt);
  return date.toLocaleString(DateTime.DATETIME_MED);
};

const ReferredUser = ({
  inviteAccepted,
  user,
}: {
  inviteAccepted: boolean;
  user: BaseUser;
}) => {
  return (
    <div className="flex w-full items-center">
      <Avatar user={user} size={8} />
      <div className="ml-2 flex flex-col">
        {inviteAccepted && (
          <span className="text-sm font-medium">{getFullName(user)}</span>
        )}
        <span className="text-sm text-gray-600">{user.email}</span>
      </div>
      <div className="ml-auto flex flex-col pl-4 text-right">
        {inviteAccepted && (
          <span className="text-xs font-medium text-gray-600">
            {getText('referrals.invites.awarded')}
          </span>
        )}
        <Tooltip
          placement="right"
          content={getAbsoluteInviteDate(user.createdAt as string)}
        >
          <span className="text-xs text-gray-500">
            {getRelativeInviteDate(user.createdAt as string)}
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default ReferredUser;
