import React from 'react';
import { Route } from 'react-router-dom';
import { Feature, FeatureValue } from '@noloco/core/src/constants/features';
import useIsFeatureEnabled from '../utils/hooks/useIsFeatureEnabled';

type Props = {
  blockTrial?: boolean;
  children?: React.ReactNode;
  onClick?: (...args: any[]) => any;
  feature: Feature;
  currentValue?: FeatureValue;
};

const FeatureLockedRoute = ({
  feature,
  blockTrial,
  currentValue,
  ...rest
}: Props) => {
  const isEnabled = useIsFeatureEnabled(feature, { blockTrial, currentValue });

  if (!isEnabled) {
    return null;
  }

  // @ts-expect-error TS(2769): No overload matches this call.
  return <Route {...rest} />;
};

export default FeatureLockedRoute;
