export const SUBSCRIPTION = 'subscription-successful';
export const UPGRADE = 'upgrade-successful';
export const DOWNGRADE = 'downgrade-successful';

const paymentStatus = [SUBSCRIPTION, UPGRADE, DOWNGRADE];

export type PaymentStatus =
  | 'subscription-successful'
  | 'upgrade-successful'
  | 'downgrade-successful';

export default paymentStatus;
