import { ReactNode } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

type DashboardNavItemProps = {
  children: ReactNode;
  className?: string;
  external?: boolean;
  to: string;
};

const DashboardNavItem = ({
  children,
  className,
  external = false,
  to,
}: DashboardNavItemProps) => (
  <Box
    className={classNames(
      className,
      'mx-1 cursor-pointer rounded px-4 py-2 font-medium text-gray-400 hover:text-gray-200',
    )}
    activeClassName="text-white"
    act
    is={external ? 'a' : NavLink}
    {...(external
      ? {
          href: to,
          target: to !== '#' ? '_blank' : undefined,
          rel: 'noopener noreferrer',
        }
      : { to })}
  >
    <div className="flex items-center">{children}</div>
  </Box>
);

export default DashboardNavItem;
