import React from 'react';
import { IconInfoCircle } from '@tabler/icons-react';
import ErrorBot from '@noloco/core/src/img/svg/ErrorBot';
import useGetFeatureFlagValue from '@noloco/core/src/utils/hooks/useGetFeatureFlagValue';
import { CORE_MAINTENANCE_MODE } from '@noloco/core/src/utils/hooks/useHasFeatureFlag';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'errors.maintenanceMode';

const MaintenanceMode = () => {
  const maintenanceMessage = useGetFeatureFlagValue(CORE_MAINTENANCE_MODE);

  return (
    <div className="App flex h-screen w-screen flex-col items-center justify-center bg-slate-800 text-white">
      <div className="mx-auto flex w-full max-w-screen-md items-center px-4 md:flex-col">
        <ErrorBot
          alt="Noloco error bot avatar"
          className="mb-8 mr-auto h-64 w-64 md:mx-auto"
        />
        <div className="ml-8 flex flex-col">
          <h1 className="max-w-screen-lg text-2xl">
            {maintenanceMessage ?? getText(LANG_KEY, 'fallbackMessage')}
          </h1>
          <p className="mt-2 max-w-screen-md text-lg text-gray-200">
            {getText(LANG_KEY, 'subText')}
          </p>
          <a
            className="mt-2 flex items-center space-x-2 text-pink-400 hover:text-pink-500 hover:underline"
            href="https://status.noloco.io"
          >
            <IconInfoCircle className="opacity-75" />

            <span>status.noloco.io</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceMode;
