import React from 'react';

const ErrorBot = (props: any) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 194 177"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.9">
      <path
        d="M38 75C35.046 86.6966 18.0959 81.6663 14.6313 93.009C11.1668 104.352 22.6565 113 32.1552 113"
        stroke="#831843"
        strokeWidth="6"
      />
    </g>
    <g opacity="0.9">
      <path
        d="M164 118C172.394 121.486 184.102 110.406 180 101.5C175.898 92.5937 164 94.8056 164 82.1951"
        stroke="#831843"
        strokeWidth="4"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M152 69C150.895 69 150 69.8954 150 71V85C150 86.1046 150.895 87 152 87H171C172.105 87 173 86.1046 173 85V71C173 69.8954 172.105 69 171 69H152ZM21 100C21 98.8954 21.8954 98 23 98H35C36.1046 98 37 98.8954 37 100V118C37 119.105 36.1046 120 35 120H23C21.8954 120 21 119.105 21 118V100ZM150 107C150 105.895 150.895 105 152 105H171C172.105 105 173 105.895 173 107V125C173 126.105 172.105 127 171 127H152C150.895 127 150 126.105 150 125V107Z"
      fill="#273951"
    />
    <mask
      id="mask0_1_2390"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="21"
      y="69"
      width="152"
      height="58"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152 69C150.895 69 150 69.8954 150 71V85C150 86.1046 150.895 87 152 87H171C172.105 87 173 86.1046 173 85V71C173 69.8954 172.105 69 171 69H152ZM21 100C21 98.8954 21.8954 98 23 98H35C36.1046 98 37 98.8954 37 100V118C37 119.105 36.1046 120 35 120H23C21.8954 120 21 119.105 21 118V100ZM150 107C150 105.895 150.895 105 152 105H171C172.105 105 173 105.895 173 107V125C173 126.105 172.105 127 171 127H152C150.895 127 150 126.105 150 125V107Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1_2390)">
      <rect x="-2.13043" y="63" width="198.261" height="76" fill="#F472B6" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.0102 13C85.0772 13 76.2143 21.8629 76.2143 32.7959V36H67.3163C66.764 36 66.3163 36.4477 66.3163 37V51C66.3163 51.5523 66.764 52 67.3163 52H124.704C125.256 52 125.704 51.5523 125.704 51V37C125.704 36.4477 125.256 36 124.704 36H115.806V32.7959C115.806 21.8629 106.943 13 96.0102 13Z"
      fill="#59C4FF"
    />
    <mask
      id="mask1_1_2390"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="66"
      y="13"
      width="60"
      height="39"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.0102 13C85.0772 13 76.2143 21.8629 76.2143 32.7959V36H67.3163C66.764 36 66.3163 36.4477 66.3163 37V51C66.3163 51.5523 66.764 52 67.3163 52H124.704C125.256 52 125.704 51.5523 125.704 51V37C125.704 36.4477 125.256 36 124.704 36H115.806V32.7959C115.806 21.8629 106.943 13 96.0102 13Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_1_2390)">
      <rect x="46.5204" width="98.9796" height="52" fill="#F472B6" />
      <ellipse
        cx="96.0102"
        cy="30"
        rx="3.95918"
        ry="6"
        fill="white"
        fillOpacity="0.6"
      />
      <rect
        x="66.3163"
        y="13"
        width="59.3878"
        height="23"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M96.0102 14.5C95.4579 14.5 95.0102 14.9477 95.0102 15.5C95.0102 16.0523 95.4579 16.5 96.0102 16.5V14.5ZM107.574 21.6837C107.943 22.0949 108.575 22.1294 108.986 21.7607C109.397 21.392 109.432 20.7598 109.063 20.3486L107.574 21.6837ZM111.62 24.051C111.366 23.5605 110.763 23.3688 110.272 23.6227C109.782 23.8766 109.59 24.48 109.844 24.9705L111.62 24.051ZM111.267 28.9538C111.382 29.4941 111.912 29.8393 112.453 29.7249C112.993 29.6104 113.338 29.0796 113.224 28.5393L111.267 28.9538ZM96.0102 16.5C100.594 16.5 104.717 18.4969 107.574 21.6837L109.063 20.3486C105.845 16.7594 101.189 14.5 96.0102 14.5V16.5ZM109.844 24.9705C110.486 26.2104 110.969 27.5473 111.267 28.9538L113.224 28.5393C112.888 26.9547 112.343 25.4482 111.62 24.051L109.844 24.9705Z"
        fill="white"
      />
    </g>
    <rect
      x="31.6735"
      y="45"
      width="128.673"
      height="120"
      rx="18"
      fill="#0076DE"
    />
    <mask
      id="mask2_1_2390"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="31"
      y="45"
      width="130"
      height="120"
    >
      <rect
        x="31.6735"
        y="45"
        width="128.673"
        height="120"
        rx="18"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_1_2390)">
      <rect x="29.6939" y="43" width="132.633" height="124" fill="#DB2777" />
    </g>
    <rect
      x="70.2755"
      y="136"
      width="3.95918"
      height="8"
      rx="1.97959"
      fill="black"
      fillOpacity="0.6"
    />
    <rect
      x="94.0306"
      y="136"
      width="3.95918"
      height="8"
      rx="1.97959"
      fill="black"
      fillOpacity="0.6"
    />
    <rect
      x="82.153"
      y="136"
      width="3.95918"
      height="8"
      rx="1.97959"
      fill="black"
      fillOpacity="0.6"
    />
    <rect
      x="105.908"
      y="136"
      width="3.95919"
      height="8"
      rx="1.97959"
      fill="black"
      fillOpacity="0.6"
    />
    <rect
      x="117.786"
      y="136"
      width="3.95918"
      height="8"
      rx="1.97959"
      fill="black"
      fillOpacity="0.6"
    />
    <rect x="39.8979" y="67" width="116" height="51" rx="18" fill="#464C4F" />
    <path
      d="M90.6136 80.956C89.0072 79.3862 86.8523 77.5808 85.0891 76.4035C76.8611 70.9089 64.3232 71.6154 57.6233 81.8587C52.4122 89.8257 53.666 100.108 60.8753 106.898C67.2226 112.863 77.9582 113.727 85.4025 108.311C88.8113 105.838 91.7499 102.777 94.8061 99.8728C97.4704 97.3611 98.3716 96.7331 99.6254 94.81C100.762 104.504 109.107 112 119.02 112C129.677 112 138.532 103.287 138.571 92.7692C138.61 81.898 129.951 73.1068 119.098 73.0675C114.671 73.0283 110.518 74.5196 107.187 77.0314C107.109 77.0706 107.07 77.1491 106.991 77.1884C106.286 77.7378 105.581 78.3658 104.954 78.9937C102.682 81.2308 99.6646 84.3705 97.0394 86.843C92.0633 91.5133 88.0669 95.8697 82.6207 100.344C77.7231 104.975 70.3179 104.543 66.008 99.7943C60.601 93.7896 63.0694 84.4097 70.7881 82.0942C75.4506 80.6813 79.7997 82.0157 83.5219 85.4301C83.8745 85.7834 84.3839 86.2936 84.7365 86.686L90.6136 80.956ZM118.824 103.562C112.438 103.405 107.579 98.4207 107.697 92.0628C107.814 86.1758 113.104 81.27 119.255 81.3878C125.563 81.5055 130.539 86.6075 130.382 92.887C130.265 98.7739 124.975 103.68 118.824 103.562Z"
      fill="white"
    />
  </svg>
);

export default ErrorBot;
