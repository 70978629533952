import React, { memo } from 'react';
import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';

type Props = {
  active: boolean;
  badge?: React.ReactNode;
  subtitle: string;
  title: string;
  value: string;
};

const PlanRadioOption = memo(
  ({ active, badge, subtitle, title, value }: Props) => (
    <RadioGroup.Option
      className={classNames(
        'flex w-full cursor-pointer flex-col rounded-lg bg-white px-4 py-3 shadow',
        {
          'ring-2 ring-pink-500 ring-opacity-75': active,
        },
      )}
      value={value}
    >
      <div className="flex items-center">
        <span
          className={classNames({
            'text-pink-700': active,
          })}
        >
          {title}
        </span>
        {badge}
      </div>
      <span className="font-light text-gray-600">{subtitle}</span>
    </RadioGroup.Option>
  ),
);

export default PlanRadioOption;
