import React, { useCallback, useMemo, useState } from 'react';
import { IconPlayerPlay, IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import { BaseModal, Button, Modal, Popover } from '@noloco/components';
import { TEXT } from '@noloco/components/src/components/button/buttonTypes';
import { LG } from '@noloco/components/src/constants/tShirtSizes';
import { INTERNAL } from '@noloco/core/src/constants/dataSources';
import { Template, TemplateProject } from '@noloco/core/src/models/Template';
import {
  PREVIEW_TEMPLATE,
  VIEW_AIRTABLE_BASE,
  VIEW_TEMPLATE_INFO,
  trackEvent,
} from '@noloco/core/src/utils/analytics';
import { NEW_TAB, useOpenUrl } from '@noloco/core/src/utils/hooks/useOpenUrl';
import { getText } from '@noloco/core/src/utils/lang';
import useTrackDashboardPage, {
  PageTypes,
} from '../utils/hooks/useTrackDashboardPage';
import DataSourceIcon from './DataSourceIcon';
import TemplateCreator from './TemplateCreator';

type TemplatesModalProps = {
  onClose: () => void;
  selectTemplate: (
    template: Template,
    templateProject: TemplateProject,
  ) => void;
  template: Template;
};

const TemplatesModal = ({
  onClose,
  selectTemplate,
  template,
}: TemplatesModalProps) => {
  const [showDataSourceChoiceModal, setShowDataSourceChoiceModal] =
    useState(false);
  useTrackDashboardPage(PageTypes.TEMPLATE, {
    template: template.id,
    templateName: template.title,
  });
  const open = useOpenUrl();

  const templateProjects = useMemo(
    () => get(template, 'projects', []),
    [template],
  );

  const onChooseTemplate = useCallback(() => {
    if (templateProjects.length === 1) {
      selectTemplate(template, templateProjects[0]);
    } else {
      setShowDataSourceChoiceModal(true);
    }
  }, [selectTemplate, template, templateProjects]);

  const onChooseTemplateSource = useCallback(
    (templateProject: TemplateProject) => {
      selectTemplate(template, templateProject);
      setShowDataSourceChoiceModal(false);
    },
    [selectTemplate, template],
  );

  if (showDataSourceChoiceModal) {
    return (
      <BaseModal closeOnOutsideClick={false}>
        <div className="relative flex flex-col p-6">
          <button
            className="absolute right-2 top-2 text-xs text-gray-500 hover:text-gray-800"
            onClick={() => {
              setShowDataSourceChoiceModal(false);
            }}
          >
            <IconX size={16} className="mr-2 flex-shrink-0" />
          </button>
          <h2 className="text-center text-lg font-medium">
            {getText('newProject.templateSourceChoice.title')}
          </h2>
          <p className="mt-2 text-center text-sm">
            {getText('newProject.templateSourceChoice.subtitle')}
          </p>
          <div className="my-6 grid grid-cols-2 gap-4">
            {templateProjects.map((templateProject) => (
              <button
                className="flex w-full cursor-pointer items-center justify-center rounded-lg border p-4 ring-pink-500 transition duration-150 ease-in-out hover:ring"
                key={templateProject.id}
                onClick={() => onChooseTemplateSource(templateProject)}
              >
                <div className="flex flex-col">
                  <div className="flex items-center justify-center">
                    <div className="flex w-8 items-center justify-center">
                      <DataSourceIcon type={templateProject.dataSourceType} />
                    </div>
                    <span className="ml-2 text-sm font-medium tracking-wider text-gray-700">
                      {getText(
                        'leftSidebar.data.sources',
                        templateProject.dataSourceType,
                      )}
                    </span>
                  </div>
                  <span className="mt-2 text-center text-xs text-gray-500">
                    {getText(
                      'newProject.templateSourceChoice',
                      templateProject.dataSourceType,
                    )}
                  </span>
                </div>
              </button>
            ))}
          </div>
        </div>
      </BaseModal>
    );
  }

  return (
    <Modal
      onClose={onClose}
      onCancel={onClose}
      confirmText={getText('newProject.useTemplate')}
      onConfirm={onChooseTemplate}
      size={LG}
      title={template.title}
      icon={
        templateProjects.length > 0 && (
          <DataSourceIcon type={first(templateProjects)!.dataSourceType} />
        )
      }
    >
      <div className="h-auto w-full pb-2">
        {template.thumbnail && (
          <img
            src={template.thumbnail}
            alt={template.title}
            className="mt-2 rounded-lg shadow-lg"
          />
        )}
        <div
          className={classNames(
            'flex items-center gap-1',
            template.thumbnail && 'mt-6',
          )}
        >
          {templateProjects.length > 1 && (
            <Popover
              content={
                <div className="flex flex-col p-2 pb-4 text-left">
                  {templateProjects.map((templateProject) => (
                    <button
                      className="flex items-center space-x-2 rounded-lg px-2 py-2 text-left hover:bg-gray-100"
                      onClick={() => {
                        open(templateProject.previewLink, NEW_TAB);
                        trackEvent(PREVIEW_TEMPLATE);
                      }}
                      key={templateProject.id}
                    >
                      <div className="flex w-8 justify-center text-gray-800">
                        <DataSourceIcon type={templateProject.dataSourceType} />
                      </div>
                      <span>
                        {getText(
                          'newProject.preview',
                          templateProject.dataSourceType,
                        )}
                      </span>
                    </button>
                  ))}
                </div>
              }
              p={0}
            >
              <Button className="flex items-center space-x-2" type={TEXT}>
                <IconPlayerPlay
                  size={16}
                  className="flex-shrink-0 text-pink-600"
                />
                <span>{getText('newProject.preview.label')}</span>
              </Button>
            </Popover>
          )}
          {templateProjects.length === 1 && (
            <Button
              className="flex items-center space-x-2"
              type={TEXT}
              onClick={() => {
                open(first(templateProjects)!.previewLink, NEW_TAB);
                trackEvent(PREVIEW_TEMPLATE);
              }}
            >
              <IconPlayerPlay
                size={16}
                className="flex-shrink-0 text-pink-600"
              />
              <span>{getText('newProject.preview.label')}</span>
            </Button>
          )}
          {templateProjects
            .filter(
              (templateProject) =>
                templateProject.dataSourceType !== INTERNAL &&
                templateProject.dataSourceLink,
            )
            .map((templateProject) => (
              <Button
                onClick={() => {
                  open(templateProject.dataSourceLink!, NEW_TAB);
                  trackEvent(VIEW_AIRTABLE_BASE);
                }}
                type={TEXT}
              >
                {getText(
                  'newProject.previewDataSource',
                  templateProject.dataSourceType,
                )}
              </Button>
            ))}
          {template.templateInfoUrl && (
            <Button
              onClick={() => {
                open(template.templateInfoUrl!, NEW_TAB);
                trackEvent(VIEW_TEMPLATE_INFO);
              }}
              type={TEXT}
            >
              {getText('newProject.moreInfo')}
            </Button>
          )}
        </div>
        {template.description && (
          <p className="my-4 text-lg text-gray-500">{template.description}</p>
        )}
        <TemplateCreator creator={template.creator} />
      </div>
    </Modal>
  );
};

export default TemplatesModal;
