import { FILE, USER } from '@noloco/core/src/constants/builtInDataTypes';
import {
  DATE,
  DECIMAL,
  DataFieldType,
  INTEGER,
  SINGLE_OPTION,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import {
  CURRENCY,
  DATE as DATE_FORMAT,
  DATE_TIME,
  MULTILINE_TEXT,
  PERCENTAGE,
  RATING,
  SINGLE_LINE_TEXT,
} from '@noloco/core/src/constants/fieldFormats';
import {
  MANY_TO_MANY,
  MANY_TO_ONE,
  Relationship,
} from '@noloco/core/src/constants/relationships';
import DataFieldOptions from '@noloco/core/src/models/DataFieldOptions';
import {
  DataFieldOption,
  FieldTypeOptions,
} from '@noloco/core/src/models/DataTypeFields';

export type SampleField = {
  name: string;
  type: DataFieldType | string;
  typeOptions?: FieldTypeOptions;
  relationship?: Relationship;
  reverseDisplay?: (dataTypeName: string) => string;
  options?: { display: string; name: string; color: string }[];
};

const sampleFields: SampleField[] = [
  {
    name: 'Name',
    type: TEXT,
    typeOptions: {
      format: SINGLE_LINE_TEXT,
    },
  },
  {
    name: 'Notes',
    type: TEXT,
    typeOptions: {
      format: MULTILINE_TEXT,
    },
  },
  {
    name: 'Started at',
    type: DATE,
    typeOptions: {
      format: DATE_TIME,
    },
  },
  {
    name: 'Price',
    type: DECIMAL,
    typeOptions: {
      format: CURRENCY,
      symbol: '$',
    },
  },
  {
    name: 'Created by',
    type: USER,
    relationship: MANY_TO_ONE,
    reverseDisplay: (dataTypeName: string) =>
      `${dataTypeName} (from Created by)`,
  },
  {
    name: 'Progress',
    type: DECIMAL,
    typeOptions: {
      format: PERCENTAGE,
    },
  },
  {
    name: 'Status',
    type: SINGLE_OPTION,
    options: new DataFieldOptions([
      { display: 'Todo', name: 'TODO', color: 'red' } as DataFieldOption,
      {
        display: 'In progress',
        name: 'IN_PROGRESS',
        color: 'blue',
      } as DataFieldOption,
      {
        display: 'Complete',
        name: 'COMPLETE',
        color: 'green',
      } as DataFieldOption,
    ]),
  },
  {
    name: 'Rating',
    type: INTEGER,
    typeOptions: {
      format: RATING,
    },
  },
  {
    name: 'Attachments',
    type: FILE,
    relationship: MANY_TO_MANY,
  },
  {
    name: 'End Date',
    type: DATE,
    typeOptions: {
      format: DATE_FORMAT,
    },
  },
];

export default sampleFields;
