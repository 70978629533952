import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { IconCopy, IconReplace } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Loader, Modal, RadioGroup, Switch } from '@noloco/components';
import { ErrorText } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { AIRTABLE, INTERNAL } from '@noloco/core/src/constants/dataSources';
import { isTrailingPlanExpired } from '@noloco/core/src/utils/billing';
import { getTextFromError } from '@noloco/core/src/utils/hooks/useAlerts';
import useCacheQuery from '@noloco/core/src/utils/hooks/useCacheQuery';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import {
  CLONE_PROJECT,
  GET_DATA_SOURCES,
  PROJECT_QUERY,
} from '../queries/project';
import { useCheckDataSourceSyncStatus } from '../utils/hooks/useCheckDataSourceSyncStatus';
import { useCloneDataSource } from '../utils/hooks/useCloneDataSource';
import useNewProjectName from '../utils/hooks/useNewProjectName';
import useTrackDashboardPage, {
  PageTypes,
} from '../utils/hooks/useTrackDashboardPage';
import { redirectToProject } from '../utils/project';
import ProjectNameField from './ProjectNameField';
import ProjectWorkspaceSelect from './ProjectWorkspaceSelect';
import CloneDataSource from './dashboard/CloneDataSource';

const replaceDataSourceOptions = [false, true].map((option, index) => ({
  value: option,
  label: ({ value, checked }: any) => (
    <div
      className={classNames(
        'flex min-h-10 w-full cursor-pointer flex-row items-center border border-gray-200 px-1 px-4 py-2 font-medium leading-5 transition duration-150 ease-in-out hover:bg-pink-200 focus:outline-none',
        {
          'bg-pink-100': checked,
          'rounded-t-lg': index === 0,
          'rounded-b-lg': index === 1,
        },
      )}
    >
      {value ? <IconReplace /> : <IconCopy />}
      <div className={'ml-4 flex flex-col'}>
        <span className={'text-base text-gray-900'}>
          {getText('newProject.clone.dataSource', value, 'label')}
        </span>
        <span className={classNames('text-sm', { 'text-pink-500': checked })}>
          {getText('newProject.clone.dataSource', value, 'description')}
        </span>
      </div>
    </div>
  ),
}));

const CloneProject = () => {
  const {
    query: { projectId, replace },
    push,
  } = useRouter();
  useTrackDashboardPage(PageTypes.CLONE_PROJECT);
  const {
    isNameUnique,
    isNameValid,
    loadingNameCheck,
    onChangeName,
    projectName,
  } = useNewProjectName();
  const [error, setError] = useState<string | null>(null);
  const [waitingForSync, setWaitingForSync] = useState(false);
  const [skipMissingFields, setSkipMissingFields] = useState(false);
  const [workspaceId, setWorkspaceId] = useState<string | undefined>(undefined);
  const [createProject, { loading: createLoading }] =
    useMutation(CLONE_PROJECT);
  const [showConfigureDataSource, setShowConfigureDataSource] = useState(
    replace === 'true',
  );
  const [willReplaceDataSource, setWillReplaceDataSource] = useState(
    replace === 'true',
  );

  const { dataSourceConnection, isValidConnection, setExternalId } =
    useCloneDataSource();

  const { data: { project: sourceProject = null } = {} } = useQuery(
    PROJECT_QUERY,
    {
      skip: !projectId,
      variables: {
        projectId,
      },
    },
  );

  const openProject = (location: string) => {
    setWaitingForSync(false);
    redirectToProject(location);
  };

  const { checkSyncStatus } = useCheckDataSourceSyncStatus(
    projectName,
    openProject,
    openProject,
  );

  const dataSourcesQueryResult = useCacheQuery(GET_DATA_SOURCES, {
    variables: { projectId },
  });

  const dataSources = useMemo(
    () => get(dataSourcesQueryResult, 'data.dataSources', []),
    [dataSourcesQueryResult],
  );
  const replaceableDataSource = useMemo(
    () =>
      dataSources.length === 1 && dataSources[0].type === AIRTABLE
        ? dataSources[0]
        : undefined,
    [dataSources],
  );

  const onConfirmOrSubmit = () => {
    setError(null);

    if (willReplaceDataSource && !showConfigureDataSource) {
      setShowConfigureDataSource(true);
    } else {
      createProject({
        variables: {
          teamId: parseInt(workspaceId!, 10),
          name: projectName,
          baseName: projectId,
          ...(willReplaceDataSource
            ? {
                dataSourceConnection,
                dataSourceType: replaceableDataSource.type,
                skipMissingFields: skipMissingFields ? true : undefined,
              }
            : {}),
        },
      })
        .then(({ data }) => {
          const clonedProject = data.cloneProject;
          if (clonedProject) {
            const clonedExternalDataSource = clonedProject.dataTypes.find(
              ({ source }: any) => get(source, 'type', INTERNAL) !== INTERNAL,
            );

            if (clonedExternalDataSource) {
              setWaitingForSync(true);
              return checkSyncStatus(clonedExternalDataSource.source);
            } else {
              return openProject(projectName);
            }
          } else {
            setError(getText('newProject.errors.generic'));
            setWaitingForSync(false);
          }
        })
        .catch((e) => {
          const { title, message } = getTextFromError(e);
          setError(`${title} ${message ?? ''}`);
          console.log(e);
        });
    }
  };

  const handleCancelOrBack = (event: any) => {
    event.preventDefault();
    if (showConfigureDataSource) {
      setShowConfigureDataSource(false);
    } else {
      push('/');
    }
  };

  return (
    <Modal
      canCancel={!createLoading && !waitingForSync}
      cancelText={
        showConfigureDataSource
          ? getText('newProject.back')
          : getText('newProject.cancel')
      }
      confirmDisabled={
        createLoading ||
        waitingForSync ||
        !isNameValid ||
        !isNameUnique ||
        (showConfigureDataSource && !isValidConnection)
      }
      confirmText={
        willReplaceDataSource && !showConfigureDataSource
          ? getText('newProject.next')
          : getText('newProject.clone.confirm')
      }
      onCancel={handleCancelOrBack}
      onConfirm={onConfirmOrSubmit}
      title={
        <span className="text-2xl">
          {showConfigureDataSource
            ? getText('newProject.clone.dataSource.title')
            : getText({ projectName: projectId }, 'newProject.clone.title')}
        </span>
      }
    >
      {!showConfigureDataSource && !createLoading && !waitingForSync && (
        <div className="flex flex-col text-base">
          <p className="w-full"></p>
          <ProjectNameField
            isNameUnique={isNameUnique}
            isNameValid={isNameValid}
            loadingNameCheck={loadingNameCheck}
            onChangeName={onChangeName}
            placeholder={getText('newProject.clone.placeholder')}
            projectName={projectName}
          />
          <ProjectWorkspaceSelect
            className="my-4"
            onChange={setWorkspaceId}
            value={workspaceId}
            isTeamDisabled={isTrailingPlanExpired}
            disabledText={getText('workspaces.select.expired')}
          />
          {replaceableDataSource && (
            <div className="my-3 w-full">
              <RadioGroup
                label={
                  <span className="text-brand-darker mb-1.5 block font-medium tracking-wider">
                    {getText(
                      { dataSource: replaceableDataSource.display },
                      'newProject.clone.dataSource.label',
                    )}
                  </span>
                }
                onChange={(newValue: any) => setWillReplaceDataSource(newValue)}
                options={replaceDataSourceOptions}
                value={willReplaceDataSource}
              />
            </div>
          )}
          <p className="my-1.5">
            {getText(
              { projectName: projectId },
              'newProject.clone.whatsCloned',
              willReplaceDataSource,
            )}
          </p>
        </div>
      )}
      {showConfigureDataSource &&
        !createLoading &&
        !waitingForSync &&
        sourceProject && (
          <>
            <CloneDataSource
              airtableBaseLink={get(replaceableDataSource, [
                'connection',
                'sharingLink',
              ])}
              dataSourceConnection={dataSourceConnection}
              newProjectName={projectName}
              setExternalId={setExternalId}
              sourceProject={sourceProject}
              surface={LIGHT}
            />
            {error && error.includes('These fields are missing:') && (
              <div className="mb-4 flex items-center">
                <label className="mr-auto">
                  {getText('newProject.clone.dataSource.skipMissingFields')}
                </label>
                <Switch
                  value={skipMissingFields}
                  onChange={() => setSkipMissingFields(!skipMissingFields)}
                />
              </div>
            )}
          </>
        )}
      <div className={'flex flex-col'}>
        {error && (
          <ErrorText className={'w-full py-4'}>
            {<span>Error: {error}</span>}
          </ErrorText>
        )}
        {(createLoading || waitingForSync || !sourceProject) && (
          <div className="text-brand w-full max-w-md px-8 py-4">
            <p className="text-sm">
              <div className="flex w-full flex-col items-center p-16">
                <Loader className="mx-auto" size="md" />
                <span className="mt-6 text-center">
                  {createLoading
                    ? getText('newProject.creation.project')
                    : getText('newProject.creation.finish')}
                </span>
              </div>
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CloneProject;
