import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import { FormField, Modal, RadioGroup } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  INTEGER,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { getText } from '@noloco/core/src/utils/lang';
import sampleFieldNames from '../../constants/sampleFieldNames';
import DataFieldIcon from '../DataFieldIcon';

const LANG_KEY = 'newProject.recommendations.dataTypes.fields.editor';

const sampleFieldNameOptions = sampleFieldNames.map((value) => ({
  value,
  label: value,
}));

const fieldTypeOptions = [TEXT, DATE, INTEGER, DECIMAL, BOOLEAN, DURATION]
  .map((dataTypeOption) => ({
    value: dataTypeOption,
  }))
  .map((dataTypeOption) => ({
    ...dataTypeOption,
    label: ({ value, checked }: any) => (
      <div
        className={classNames(
          'h--full flex h-full min-h-10 cursor-pointer items-center rounded-lg border-2 border-transparent bg-white p-2 text-sm font-medium transition duration-150 ease-in-out hover:bg-pink-200 focus:outline-none',
          {
            'border-pink-300 bg-pink-200': checked,
          },
        )}
      >
        <DataFieldIcon
          field={{ type: value } as DataField}
          className="mr-2 w-5 text-gray-800"
        />
        {getText('data.types', value, 'label')}
      </div>
    ),
  }));

type Props = {
  dataType: any;
  existingField?: any;
  onSaveField: (field: any) => void;
  onClose: () => void;
};

const EditRecommendedFieldModal = ({
  dataType,
  existingField,
  onSaveField,
  onClose,
}: Props) => {
  const [fieldName, setFieldName] = useState(existingField?.display ?? null);
  const [fieldType, setFieldType] = useState(existingField?.type ?? null);

  const confirmDisabled = useMemo(
    () => !fieldName || !fieldType,
    [fieldName, fieldType],
  );

  const onConfirm = useCallback(() => {
    const field = {
      name: camelCase(fieldName!),
      display: fieldName,
      type: fieldType,
    };

    onSaveField(field);
    onClose();
  }, [fieldName, fieldType, onSaveField, onClose]);

  return (
    <Modal
      canCancel={true}
      canConfirm={true}
      confirmDisabled={confirmDisabled}
      confirmText={getText(LANG_KEY, 'confirmText')}
      onCancel={onClose}
      onClose={onClose}
      onConfirm={onConfirm}
      title={getText(
        { dataType: dataType.display },
        LANG_KEY,
        'title',
        existingField ? 'edit' : 'add',
      )}
    >
      <label className="flex items-center text-lg font-medium text-gray-900">
        {getText(LANG_KEY, 'name.label')}
      </label>
      <FormField
        className="mb-3 cursor-pointer border-2 border-transparent focus:border-pink-300"
        help={getText(LANG_KEY, 'name.help')}
        inputType="autocomplete"
        name="fieldName"
        onChange={({ target: { value } }: any) => setFieldName(value)}
        options={sampleFieldNameOptions}
        placeholder={getText(LANG_KEY, 'name.placeholder')}
        surface={LIGHT}
        value={fieldName}
      />
      <label className="mb-3 mt-6 flex items-center text-lg font-medium text-gray-900">
        {getText(LANG_KEY, 'type.label')}
      </label>
      <p className="mb-2 text-sm text-gray-600">
        {getText(LANG_KEY, 'type.help')}
      </p>
      <RadioGroup
        className="grid max-w-md grid-cols-2 gap-2 sm:grid-cols-1 md:grid-cols-2"
        onChange={(value: any) => {
          setFieldType(value);
        }}
        options={fieldTypeOptions}
        value={fieldType}
      />
    </Modal>
  );
};

export default EditRecommendedFieldModal;
