import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Register as RegisterLayout } from '@noloco/components';
import FormField from '@noloco/components/src/components/form/FormField';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import logoBlack from '../img/infinity-logo-black.svg';
import useTrackDashboardPage, {
  PageTypes,
} from '../utils/hooks/useTrackDashboardPage';
import SidePanel from './SidePanel';

export type Props = {
  showOnboarding: boolean;
};

const Join = ({ showOnboarding }: Props) => {
  const {
    query: { invitationToken },
    push,
  } = useRouter();
  const [errors, setErrors] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { acceptInvite } = useDashboardAuth();
  useTrackDashboardPage(
    showOnboarding ? PageTypes.ACCEPT_REFERRAL : PageTypes.JOIN,
  );

  const handleJoin = ({ email, password, confirmPassword }: any) => {
    try {
      acceptInvite(password, confirmPassword, {
        email,
        firstName,
        lastName,
        invitationToken,
      })
        .then(() => {
          push(showOnboarding ? `/onboarding?step=0` : `/`);
        })
        .catch((error: any) => {
          if (error.graphQLErrors) {
            setErrors(error.graphQLErrors.map((er: any) => er.message));
          } else {
            // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
            setErrors(['Something went wrong, please try again']);
          }

          console.warn('ERROR', error);
        });
    } catch (error) {
      // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
      setErrors([(error as any).message]);
    }
  };

  return (
    <RegisterLayout
      buttonText={getText('auth.join.button')}
      errors={errors}
      forgotText={getText('auth.register.forgot')}
      logoUrl={logoBlack}
      onSubmit={handleJoin}
      loginText={
        <span>
          <span>{getText('auth.register.or')}</span>
          <Link
            to="/login"
            className="ml-1 font-medium text-teal-600 transition duration-150 ease-in-out hover:text-teal-500 focus:underline focus:outline-none"
          >
            {getText('auth.register.loginLink')}
          </Link>
        </span>
      }
      titleText={getText('auth.join.title')}
      emailLabel={getText('auth.join.fields.email')}
      passwordLabel={getText('auth.fields.password')}
      confirmPasswordLabel={getText('auth.fields.confirmPassword')}
      rememberLabel={getText('auth.fields.rememberMe')}
      type="SPLIT"
      errorTexts={{
        empty: getText('auth.register.validation.empty'),
        email: {
          invalid: getText('auth.register.validation.email.invalid'),
        },
        phoneNumber: {
          invalid: getText('auth.register.validation.phoneNumber.invalid'),
        },
        password: {
          invalid: getText('auth.register.validation.password.invalid'),
          tooShort: getText('auth.register.validation.password.tooShort'),
          tooLong: getText('auth.register.validation.password.tooLong'),
          numbers: getText('auth.register.validation.password.numbers'),
          case: getText('auth.register.validation.password.case'),
          symbol: getText('auth.register.validation.password.symbol'),
        },
        confirmPassword: {
          invalid: getText('auth.register.validation.confirmPassword.invalid'),
        },
      }}
      showPhoneNumber={false}
      surface={LIGHT}
      splitChildren={<SidePanel />}
    >
      <div className="mb-4 flex flex-wrap">
        <div className="w-1/2 pr-8">
          <FormField
            aria-label="given-name"
            autoComplete="given-name"
            name="given-name"
            type="text"
            onChange={({ target: { value } }: any) => setFirstName(value)}
            required
            errorType="below-solid"
            label={getText('auth.fields.firstName')}
            placeholder=""
            value={firstName}
            surface={LIGHT}
          />
        </div>
        <div className="w-1/2">
          <FormField
            aria-label="family-name"
            autoComplete="family-name"
            name="family-name"
            type="text"
            onChange={({ target: { value } }: any) => setLastName(value)}
            required
            errorType="below-solid"
            label={getText('auth.fields.lastName')}
            placeholder=""
            value={lastName}
            surface={LIGHT}
          />
        </div>
      </div>
    </RegisterLayout>
  );
};

export default Join;
