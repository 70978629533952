import React, { useMemo } from 'react';
import get from 'lodash/get';
import { Button } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import {
  CONFIGURE_TEMPLATE_SUBMIT,
  trackEvent,
} from '@noloco/core/src/utils/analytics';
import { getText } from '@noloco/core/src/utils/lang';
import ConnectAirtableBase from '../dataTable/ConnectAirtableBase';

const CloneDataSource = ({
  airtableConnected = false,
  authToken,
  airtableBaseLink,
  dataSourceConnection,
  authorizationRedirect,
  setExternalId,
  inOnboarding = false,
  onCreate = null,
  sourceProject,
  isValid,
  surface = DARK,
}: any) => {
  const { externalId } = dataSourceConnection;

  const hasAirtableOAuthIntegration = useMemo(
    () =>
      airtableConnected || !!get(sourceProject, 'integrations.airtable', false),
    [airtableConnected, sourceProject],
  );

  const token = useMemo(
    () => authToken || get(sourceProject, 'apiKeys.project'),
    [authToken, sourceProject],
  );

  return (
    <div>
      <div className="flex w-full flex-shrink-0 flex-col text-lg">
        <ConnectAirtableBase
          authToken={token}
          disabled={false}
          hasAirtableOAuthIntegration={hasAirtableOAuthIntegration}
          authorizationRedirect={authorizationRedirect}
          onChange={setExternalId}
          projectName={sourceProject ? sourceProject.name : undefined}
          value={externalId}
          sharingLinkToClone={airtableBaseLink}
          surface={surface}
        />
      </div>
      {inOnboarding && (
        <div className="mt-6">
          <Button
            disabled={!isValid}
            onClick={() => {
              onCreate();
              trackEvent(CONFIGURE_TEMPLATE_SUBMIT);
            }}
            className="w-full"
          >
            {getText('newProject.next')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CloneDataSource;
