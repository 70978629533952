import React, { useMemo } from 'react';
import { IconCheck } from '@tabler/icons-react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Button, ErrorText, Loader } from '@noloco/components';
import useLocale from '@noloco/components/src/utils/hooks/useLocale';
import {
  MONTH,
  PlanInterval,
  YEAR,
} from '@noloco/core/src/constants/accountPlanIntervals';
import {
  EXTERNAL_USER_ADDON_ACTIVATION_PRICES,
  EXTERNAL_USER_ADDON_ADDITIONAL_USERS_PRICES,
  PLAN_PRICES,
} from '@noloco/core/src/constants/accountPlanPrices';
import { CUSTOMER_PORTAL } from '@noloco/core/src/constants/accountPlanProducts';
import { SEAT_BASED_PLANS } from '@noloco/core/src/constants/accountPlanTypes';
import { AccountPlan } from '@noloco/core/src/constants/accountPlans';
import { BillingPlan } from '@noloco/core/src/models/BillingPlan';
import { getText } from '@noloco/core/src/utils/lang';
import { getProjectDomain } from '@noloco/core/src/utils/pages';
import CostLineItem from './CostLineItem';

type Props = {
  balance: number;
  billingPlan: BillingPlan;
  className?: string;
  disabled: boolean;
  discount: number;
  errorMessage: string | null;
  hasPaymentMethod: boolean;
  interval: PlanInterval;
  loading: boolean;
  payKey: string;
  recalculating: boolean;
  selectedPlan: AccountPlan;
  success: boolean;
  usage: {
    seats: number;
    addons: { project: string; external: number }[];
  };
};

const CostBreakdown = ({
  balance,
  billingPlan,
  className,
  disabled,
  discount,
  errorMessage,
  hasPaymentMethod,
  interval,
  loading,
  payKey,
  recalculating,
  selectedPlan,
  success,
  usage,
}: Props) => {
  const locale = useLocale();

  const isFlatPlan = !SEAT_BASED_PLANS.includes(selectedPlan);

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat(locale.code, {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'narrowSymbol',
      }),
    [locale],
  );

  const nextBillingDate = useMemo(() => {
    if (billingPlan.periodEnd) {
      const basePeriodEnd = DateTime.fromISO(billingPlan.periodEnd).minus({
        days: 3,
      });

      if (billingPlan.interval !== interval && billingPlan.interval) {
        // if it's now a year it will be 11 months from the base period end
        if (interval === YEAR) {
          return basePeriodEnd.plus({ months: 11 });
        }

        // if it's now a month it will be 11 months sooner...
        return basePeriodEnd.minus({ months: 11 });
      }
    }

    return DateTime.now().plus({
      [interval === MONTH ? 'months' : 'years']: 1,
    });
  }, [billingPlan.interval, billingPlan.periodEnd, interval]);

  const planCost = PLAN_PRICES[interval][selectedPlan];
  const cost = useMemo(() => {
    let baseCost = planCost;

    if (interval === YEAR) {
      baseCost = baseCost * 12;
    }

    if (isFlatPlan) {
      return baseCost;
    }

    baseCost = baseCost * usage.seats;

    return baseCost;
  }, [planCost, interval, isFlatPlan, usage.seats]);

  const discountedPrice = cost * discount;

  const externalUsersActivationCost =
    EXTERNAL_USER_ADDON_ACTIVATION_PRICES[selectedPlan] ?? 0;
  const externalUsersAdditionalUsersCost =
    EXTERNAL_USER_ADDON_ADDITIONAL_USERS_PRICES[selectedPlan] ?? 0;

  const externalUsersAdditionalUsersBundles = useMemo(
    () =>
      usage.addons.reduce(
        (bundles, { external }) =>
          bundles + Math.ceil((Math.max(external, 1) - 100) / 100),
        0,
      ),
    [usage.addons],
  );
  const externalUsersCost = useMemo(
    () =>
      externalUsersActivationCost * usage.addons.length +
      externalUsersAdditionalUsersCost * externalUsersAdditionalUsersBundles,
    [
      externalUsersActivationCost,
      externalUsersAdditionalUsersBundles,
      externalUsersAdditionalUsersCost,
      usage.addons.length,
    ],
  );

  const monthCost = useMemo(() => {
    if (interval === YEAR) {
      return externalUsersCost;
    } else {
      return discountedPrice + externalUsersCost;
    }
  }, [discountedPrice, externalUsersCost, interval]);

  const yearCost = useMemo(() => {
    if (interval === YEAR) {
      return discountedPrice;
    } else {
      return null;
    }
  }, [discountedPrice, interval]);
  const totalCost = useMemo(
    () => Math.max(monthCost + (yearCost ?? 0) + balance, 0),
    [balance, monthCost, yearCost],
  );

  const buttonPayKey = useMemo(
    () =>
      selectedPlan === billingPlan.type ||
      !SEAT_BASED_PLANS.includes(billingPlan.type)
        ? 'change'
        : payKey,
    [billingPlan.type, payKey, selectedPlan],
  );

  return (
    <div
      className={classNames(
        'flex h-full flex-col space-y-2 rounded-lg bg-white p-4 shadow',
        className,
      )}
    >
      <CostLineItem
        cost={cost}
        costTooltip={
          isFlatPlan
            ? undefined
            : getText('billing.changePlan.activeUserDisclaimer')
        }
        currencyFormatter={currencyFormatter}
        discount={discount !== 1 ? discount : undefined}
        interval={interval}
        intervalTooltip={getText(
          {
            amount: discountedPrice,
            nextBillingDate: nextBillingDate.toLocaleString(DateTime.DATE_MED),
          },
          'billing.changePlan.confirmChange',
          isFlatPlan ? 'flat' : 'seats',
          interval,
        )}
        title={getText('billing.plans', selectedPlan, 'name')}
        unitCost={planCost}
        unitLabel={
          isFlatPlan
            ? undefined
            : getText({ context: usage.seats }, 'billing.activeUsers')
        }
      />
      <hr />
      {!isFlatPlan &&
        usage.addons.map(({ project, external }) => (
          <>
            <CostLineItem
              cost={externalUsersActivationCost}
              costTooltip={getText(
                'billing.changePlan.externalUserActivationDisclaimer',
              )}
              currencyFormatter={currencyFormatter}
              interval={MONTH}
              title={getText(
                'billing.plan.product',
                CUSTOMER_PORTAL,
                'lineItem.activation',
              )}
              unitCost={externalUsersActivationCost}
            >
              <a
                className="text-sm text-blue-500 hover:text-blue-800 hover:underline"
                href={getProjectDomain(project)}
                target="_blank"
                rel="noreferrer"
              >
                {project}
              </a>
            </CostLineItem>
            <hr />
            {external > 100 && (
              <>
                <CostLineItem
                  cost={externalUsersAdditionalUsersCost}
                  costTooltip={getText(
                    'billing.changePlan.externalUserAdditionalDisclaimer',
                  )}
                  currencyFormatter={currencyFormatter}
                  interval={MONTH}
                  title={getText(
                    'billing.plan.product',
                    CUSTOMER_PORTAL,
                    'lineItem.additional.title',
                  )}
                  unitCost={externalUsersAdditionalUsersCost}
                >
                  <a
                    className="text-sm text-blue-500 hover:text-blue-800 hover:underline"
                    href={getProjectDomain(project)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {project}
                  </a>
                </CostLineItem>
                <hr />
              </>
            )}
          </>
        ))}
      {balance !== 0 && (
        <>
          <CostLineItem
            cost={-balance}
            costTooltip={getText('billing.credit.tooltip')}
            currencyFormatter={currencyFormatter}
            title={getText('billing.credit.label')}
          />
          <hr />
        </>
      )}
      <div className="w-full p-4">
        <div className="text-md mb-2 flex flex-row font-medium">
          <h2>Total</h2>
          <div className="ml-auto">
            {recalculating ? <Loader /> : currencyFormatter.format(totalCost)}
          </div>
        </div>
      </div>
      {errorMessage && (
        <ErrorText className="mx-4 mt-8 text-sm" type="below-solid">
          {errorMessage}
        </ErrorText>
      )}
      <Button
        size="lg"
        className="mx-4 my-8 rounded-lg"
        submitFormOnClick={true}
        disabled={(disabled || recalculating) && (!success || loading)}
      >
        {!loading && !success && !hasPaymentMethod && (
          <span>
            {getText(
              { amount: currencyFormatter.format(totalCost) },
              'billing.addPlan.pay',
            )}
          </span>
        )}
        {!loading && !success && hasPaymentMethod && (
          <span>{getText('billing.changePlan', buttonPayKey, 'pay')}</span>
        )}
        {loading && !success && <Loader className="mx-auto" size="sm" />}
        {!loading && success && <IconCheck className="mx-auto" size={16} />}
      </Button>
    </div>
  );
};

export default CostBreakdown;
