import { useEffect } from 'react';
import { IS_PRODUCTION } from '@noloco/core/src/constants/env';
import { trackPage } from '@noloco/core/src/utils/analytics';

type PageType =
  | 'login'
  | 'register'
  | 'forgot password'
  | 'reset password'
  | 'join'
  | 'accept referral'
  | 'onboarding'
  | 'new project'
  | 'clone project'
  | 'template'
  | 'clone template'
  | 'project list'
  | 'refer'
  | 'billing'
  | 'change plan'
  | 'project redirect'
  | 'template redirect';

export const PageTypes: Record<string, PageType> = {
  LOGIN: 'login',
  REGISTER: 'register',
  ONBOARDING: 'onboarding',
  FORGOT_PASSWORD: 'forgot password',
  RESET_PASSWORD: 'reset password',
  JOIN: 'join',
  ACCEPT_REFERRAL: 'accept referral',
  NEW_PROJECT: 'new project',
  CLONE_PROJECT: 'clone project',
  TEMPLATE: 'template',
  CLONE_TEMPLATE: 'clone template',
  PROJECT_LIST: 'project list',
  REFER: 'refer',
  BILLING: 'billing',
  CHANGE_PLAN: 'change plan',
  PROJECT_REDIRECT: 'project redirect',
  TEMPLATE_REDIRECT: 'template redirect',
};

const useTrackDashboardPage = (
  pageType: PageType,
  properties?: Record<string, any>,
  enabled: boolean = true,
) => {
  useEffect(() => {
    if (window.analytics && IS_PRODUCTION && enabled) {
      trackPage('Dashboard', pageType, properties);
    }
  }, [enabled, pageType, properties]);
};

export default useTrackDashboardPage;
