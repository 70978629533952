import React from 'react';
import { IconCircleX } from '@tabler/icons-react';
import classNames from 'classnames';
import { Button } from '@noloco/components';
import { Variant } from '@noloco/components/src/constants/variants';
import {
  AccountPlan,
  ENTERPRISE,
  EXPERT,
  FREE,
} from '@noloco/core/src/constants/accountPlans';
import { getText } from '@noloco/core/src/utils/lang';

type Props = {
  activePlan: AccountPlan | undefined;
  className?: string;
  onClick: () => void;
  variant?: Variant;
};

const LANG_KEY = 'billing.cancel';

const CancelPlanButton = ({
  activePlan,
  className,
  onClick,
  variant,
}: Props) => {
  if (
    activePlan === FREE ||
    activePlan === EXPERT ||
    activePlan === ENTERPRISE
  ) {
    return null;
  }

  return (
    <Button
      className={classNames(className, 'flex items-center')}
      onClick={onClick}
      type="text"
      variant={variant}
      size="sm"
    >
      <span> {getText(LANG_KEY, 'button')}</span>
      <IconCircleX className="ml-2 opacity-75" />
    </Button>
  );
};

export default CancelPlanButton;
