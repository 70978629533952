import React, { useCallback, useMemo, useState } from 'react';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { IconInfoCircle, IconPencilPlus } from '@tabler/icons-react';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import {
  Button,
  FormField,
  Loader,
  RadioGroup,
  Tooltip,
} from '@noloco/components';
import { RIGHT } from '@noloco/components/src/components/navigation/headerNavTypes';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { CREATE } from '@noloco/core/src/constants/actionTypes';
import { FILE, USER } from '@noloco/core/src/constants/builtInDataTypes';
import { TABLE } from '@noloco/core/src/constants/collectionLayouts';
import { INTERNAL } from '@noloco/core/src/constants/dataSources';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  INTEGER,
  NUMERIC_DATATYPES,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import { SINGLE_LINE_TEXT } from '@noloco/core/src/constants/fieldFormats';
import Icon from '@noloco/core/src/elements/Icon';
import FieldCell from '@noloco/core/src/elements/sections/collections/FieldCell';
import DataFieldInput from '@noloco/core/src/elements/sections/forms/DataFieldInput';
import DataTypeFields, {
  DataField,
} from '@noloco/core/src/models/DataTypeFields';
import { DataTypeArray } from '@noloco/core/src/models/DataTypes';
import { RecordValue } from '@noloco/core/src/models/Record';
import { getMutationQueryString } from '@noloco/core/src/queries/project';
import { getDataTypesWithRelations } from '@noloco/core/src/utils/data';
import { getFieldMutationInputName } from '@noloco/core/src/utils/fields';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import { AuthWrapperProvider } from '@noloco/core/src/utils/hooks/useAuthWrapper';
import usePromiseQuery from '@noloco/core/src/utils/hooks/usePromiseQuery';
import { getText } from '@noloco/core/src/utils/lang';
import sampleCollectionNames from '../constants/sampleCollectionNames';
import sampleFieldNames from '../constants/sampleFieldNames';
import sampleFields, { SampleField } from '../constants/sampleFields';
import {
  ADD_DATA_FIELD,
  ADD_DATA_TYPE,
  SUGGEST_LAYOUT,
} from '../queries/project';
import { useMutateProjectElements } from '../utils/hooks/projectHooks';
import {
  useDataTypeNameValidation,
  useFieldNameValidation,
} from '../utils/hooks/useDataTypeNameValidation';
import { generateViewFromDataSource } from '../utils/layout';
import { getSampleFieldValue } from '../utils/sampleFieldData';
import DataFieldIcon from './DataFieldIcon';

const LANG_KEY = 'newProject';
const AVAILABLE_DATA_TYPES = [TEXT, DATE, INTEGER, DECIMAL, BOOLEAN, DURATION];
const DEFAULT_NODE_QUERY = {
  id: true,
  uuid: true,
  createdAt: true,
  updatedAt: true,
};

const sampleCollectionNameOptions = sampleCollectionNames.map((value) => ({
  value,
  label: value,
}));

const sampleFieldNameOptions = sampleFieldNames.map((value) => ({
  value,
  label: value,
}));

const ProjectCreateCollection = ({
  project,
  projectLoading,
  collectionName,
  setCollectionName,
  fieldName,
  setFieldName,
  collectionData,
  setCollectionData,
  fieldsToCreate,
  setFieldsToCreate,
  fieldType,
  setFieldType,
  dataType,
  setDataType,
  isValid,
  icon,
  handleNextOrSubmit,
}: any) => {
  const apolloClient = useApolloClient();
  const [addCustomField, setAddCustomField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveDataType] = useMutation(ADD_DATA_TYPE);
  const [saveDataField] = useMutation(ADD_DATA_FIELD);
  const errorAlert = useGraphQlErrorAlert();
  const dataTypes = getDataTypesWithRelations(project.dataTypes);
  const [updateElements]: any = useMutateProjectElements();
  const [suggestLayout] = usePromiseQuery(SUGGEST_LAYOUT);
  const {
    isValid: isDisplayNameValid,
    validationMessage: nameValidationMessage,
  } = useDataTypeNameValidation(collectionName, dataTypes);

  const dataTypeWithFieldsToCreate = useMemo(
    () => ({
      ...dataType,
      fields: [
        ...get(dataType, ['fields'], []),
        ...fieldsToCreate.map((f: SampleField) => ({
          ...f,
          display: f.name,
          name: camelCase(f.name),
        })),
      ],
    }),
    [dataType, fieldsToCreate],
  );
  const { validationMessage: fieldValidationMessage } = useFieldNameValidation(
    fieldName,
    fieldType || TEXT,
    dataTypeWithFieldsToCreate,
    dataTypes,
  );

  const defaultField = useMemo(
    () =>
      ({
        id: 1,
        type: fieldType,
        typeOptions: {},
      }) as DataField,
    [fieldType],
  );

  const onToggleFieldToCreate = useCallback(
    (selectedField) => {
      if (fieldsToCreate.includes(selectedField)) {
        setFieldsToCreate(
          fieldsToCreate.filter((f: SampleField) => f !== selectedField),
        );
      } else {
        setFieldsToCreate([...fieldsToCreate, selectedField]);
      }
    },
    [fieldsToCreate, setFieldsToCreate],
  );

  const addItems = useCallback(
    async (newDataType, newField, additionalFields = []) => {
      newDataType.fields = new DataTypeFields([
        ...newDataType.fields,
        ...(newField ? [newField] : []),
        ...additionalFields,
      ]);

      const queryString = gql`
        ${getMutationQueryString(
          CREATE,
          newDataType.apiName,
          newDataType.fields,
          {
            ...DEFAULT_NODE_QUERY,
            ...(newField ? { [newField.name]: true } : {}),
          },
        )}
      `;

      await Promise.all(
        collectionData.map((item: any, index: number) => {
          const variables = {
            id: undefined,
            ...(newField ? { [newField.name]: item.value } : {}),
            ...additionalFields.reduce(
              (acc: Record<string, RecordValue>, field: DataField) => {
                if (field.type === FILE) {
                  return acc;
                }

                const key = getFieldMutationInputName(field);
                const value =
                  field.type === USER ? 1 : getSampleFieldValue(field, index);

                return {
                  ...acc,
                  [key]: value,
                };
              },
              {},
            ),
          };

          return apolloClient.mutate({
            mutation: queryString,
            variables,
            context: {
              authQuery: true,
              projectQuery: true,
              projectName: project.name,
            },
          });
        }),
      );
    },
    [collectionData, apolloClient, project.name],
  );

  const handleSave = useCallback(
    async (event) => {
      if (dataType) {
        return handleNextOrSubmit(event);
      }

      setLoading(true);

      try {
        const {
          data: { addDataType },
        } = await saveDataType({
          variables: {
            projectName: project && project.name,
            display: collectionName,
          },
        });

        setDataType(addDataType);
        let newDataField;
        if (addCustomField && fieldName) {
          const {
            data: { addDataField },
          } = await saveDataField({
            variables: {
              projectName: project && project.name,
              dataTypeId: addDataType.id,
              display: fieldName,
              type: fieldType || TEXT,
            },
          });

          newDataField = addDataField;
        }

        const additionalFields = await Promise.all(
          fieldsToCreate.map((fieldToCreate: SampleField) => {
            return saveDataField({
              variables: {
                projectName: project && project.name,
                dataTypeId: addDataType.id,
                display: fieldToCreate.name,
                type: fieldToCreate.type,
                options: (fieldToCreate.options ?? []).map((option) => ({
                  display: option.display,
                  color: option.color,
                })),
                reverseDisplay: fieldToCreate.reverseDisplay
                  ? fieldToCreate.reverseDisplay(addDataType.display)
                  : undefined,
                relationship: fieldToCreate.relationship,
                typeOptions: fieldToCreate.typeOptions,
              },
            }).then(({ data }: any) => data.addDataField);
          }),
        );

        await addItems(addDataType, newDataField, additionalFields);

        let layout = TABLE;
        let layoutConfig = {};

        try {
          const { data } = await suggestLayout({
            variables: {
              projectName: project.name,
              dataTypeId: addDataType.id,
            },
          });

          if (data?.suggestLayout?.layout) {
            layout = data?.suggestLayout?.layout;
            layoutConfig = data?.suggestLayout;
          }
        } catch (error) {
          console.error('Error suggesting layout for', addDataType.id, error);
        }

        const newView = generateViewFromDataSource(
          addDataType.display,
          DataTypeArray.formatDataType(addDataType),
          project,
          {
            userGenerated: false,
            createdAt: new Date(),
          },
          {
            userGenerated: false,
            createdAt: new Date(),
          },
          layout,
          false,
          layoutConfig,
        );
        await updateElements([], [newView], project.name);

        setLoading(false);

        handleNextOrSubmit(event);
      } catch (e) {
        console.error(e);
        setLoading(false);
        errorAlert(
          getText({ field: fieldName }, 'data.dataTypes.error.field'),
          e,
        );
      }
    },
    [
      dataType,
      handleNextOrSubmit,
      saveDataType,
      project,
      collectionName,
      setDataType,
      addCustomField,
      fieldName,
      fieldsToCreate,
      saveDataField,
      suggestLayout,
      fieldType,
      addItems,
      errorAlert,
      updateElements,
    ],
  );

  return (
    <AuthWrapperProvider dataTypes={dataTypes}>
      <div className="overflow-hidden">
        <h1 className="mb-3 text-lg text-gray-500">
          {getText(LANG_KEY, 'COLLECTION_SUBTITLE')}
        </h1>
        <hr className="mb-6 mt-3 bg-gray-100" />
        <div className="grid grid-cols-12 gap-4 sm:min-h-full sm:grid-cols-1 md:min-h-full md:grid-cols-1">
          <div className="max-h-screen-75 col-span-4 overflow-y-auto border-r border-gray-200 pr-4 sm:col-span-1 sm:border-r-0 sm:pr-0 md:col-span-1 md:border-r-0 md:pr-0">
            <h2 className="mt-3 flex items-center text-lg font-medium text-gray-900">
              {getText(LANG_KEY, 'collectionQuestion', 'collectionName')}
              <Tooltip
                placement={RIGHT}
                content={getText(LANG_KEY, 'collectionTooltips', 'collection')}
              >
                <div className="ml-1">
                  <IconInfoCircle className="text-gray-500" size={16} />
                </div>
              </Tooltip>
            </h2>
            <FormField
              className="mb-3 w-full cursor-pointer border-2 border-transparent focus:border-pink-300"
              errorMessage={
                dataType || !nameValidationMessage
                  ? null
                  : nameValidationMessage
              }
              errorType="below-solid"
              help={getText(
                LANG_KEY,
                'collectionQuestion',
                'collectionNameHelp',
              )}
              placeholder={getText(
                LANG_KEY,
                'collectionDefaults.collectionName',
              )}
              name="collectionName"
              value={collectionName}
              onChange={({ target: { value } }: any) =>
                setCollectionName(value)
              }
              options={sampleCollectionNameOptions}
              inputType="autocomplete"
              surface={LIGHT}
              disabled={dataType && dataType.source.type === INTERNAL}
            />
            <label className="mt-6 flex items-center text-lg font-medium text-gray-900">
              {getText(
                { collection: collectionName },
                LANG_KEY,
                'collectionQuestion',
                'addField',
              )}
              <Tooltip
                placement={RIGHT}
                content={getText(LANG_KEY, 'collectionTooltips', 'field')}
              >
                <div className="ml-1">
                  <IconInfoCircle className="text-gray-500" size={16} />
                </div>
              </Tooltip>
            </label>
            <p className="mb-2 text-sm text-gray-600">
              {getText(LANG_KEY, 'collectionQuestion', 'addFieldHelp')}
            </p>
            <div className="mt-4 flex flex-wrap gap-x-2">
              {sampleFields.map((sampleField) => (
                <div
                  className={classNames(
                    'mb-2 flex h-full min-h-10 cursor-pointer items-center rounded-lg border-2 border-transparent bg-white p-2 text-sm font-medium transition duration-150 ease-in-out hover:bg-pink-200 focus:outline-none',
                    {
                      'border-pink-300 bg-pink-200':
                        fieldsToCreate.includes(sampleField),
                    },
                  )}
                  key={sampleField.name}
                  onClick={() => onToggleFieldToCreate(sampleField)}
                >
                  <DataFieldIcon
                    // @ts-expect-error TS(2739): Type '{ type: any; }' is missing the following properties from type 'DataField': display, name, id, apiName. Remove this comment to see the full error message
                    field={sampleField}
                    className="mr-2 w-5 text-gray-800"
                  />
                  <span>{sampleField.name}</span>
                </div>
              ))}
              <div
                className={classNames(
                  'mb-2 flex h-full min-h-10 cursor-pointer items-center rounded-lg border-2 border-transparent bg-white p-2 text-sm font-medium transition duration-150 ease-in-out hover:bg-pink-200 focus:outline-none',
                  {
                    'border-pink-300 bg-pink-200': addCustomField,
                  },
                )}
                onClick={() => setAddCustomField(!addCustomField)}
              >
                <IconPencilPlus className="mr-2 w-5 text-gray-800" />
                <span>
                  {getText(LANG_KEY, 'collectionQuestion.customField.button')}
                </span>
              </div>
            </div>
            {addCustomField && (
              <>
                <label className="mt-6 flex items-center text-lg font-medium text-gray-900">
                  {getText(LANG_KEY, 'collectionQuestion.customField.label')}
                </label>
                <FormField
                  className="mb-3 cursor-pointer border-2 border-transparent focus:border-pink-300"
                  errorMessage={fieldValidationMessage}
                  errorType="below-solid"
                  help={getText(
                    LANG_KEY,
                    'collectionQuestion.customField.help',
                  )}
                  placeholder={getText(
                    LANG_KEY,
                    'collectionDefaults.fieldName',
                  )}
                  name="fieldName"
                  inputType="autocomplete"
                  options={sampleFieldNameOptions}
                  value={fieldName}
                  onChange={({ target: { value } }: any) => setFieldName(value)}
                  surface={LIGHT}
                  disabled={dataType && dataType.source.type === INTERNAL}
                />
                <label className="mb-3 mt-6 flex items-center text-lg font-medium text-gray-900">
                  {getText(LANG_KEY, 'collectionQuestion', 'fieldType')}
                  <Tooltip
                    placement={RIGHT}
                    content={getText(LANG_KEY, 'collectionTooltips', 'type')}
                  >
                    <div className="ml-1">
                      <IconInfoCircle className="text-gray-500" size={16} />
                    </div>
                  </Tooltip>
                </label>
                <RadioGroup
                  className="grid max-w-md grid-cols-2 gap-2 sm:grid-cols-1 md:grid-cols-2"
                  onChange={(value: any) => {
                    setCollectionData([null, null, null]);
                    setFieldType(value);
                  }}
                  value={fieldType}
                  options={AVAILABLE_DATA_TYPES.map((dataTypeOption) => ({
                    value: dataTypeOption,
                  })).map((dataTypeOption) => ({
                    ...dataTypeOption,
                    label: ({ value, checked }: any) => (
                      <div
                        className={classNames(
                          'h--full flex h-full min-h-10 cursor-pointer items-center rounded-lg border-2 border-transparent bg-white p-2 text-sm font-medium transition duration-150 ease-in-out hover:bg-pink-200 focus:outline-none',
                          {
                            'border-pink-300 bg-pink-200': checked,
                          },
                        )}
                      >
                        <DataFieldIcon
                          // @ts-expect-error TS(2739): Type '{ type: any; }' is missing the following properties from type 'DataField': display, name, id, apiName. Remove this comment to see the full error message
                          field={{ type: value }}
                          className="mr-2 w-5 text-gray-800"
                        />
                        {getText('data.types', value, 'label')}
                      </div>
                    ),
                  }))}
                />
              </>
            )}
            {addCustomField && fieldName && fieldType && (
              <>
                <label className="mt-6 flex items-center text-lg font-medium text-gray-900">
                  {getText(
                    { collection: collectionName },
                    LANG_KEY,
                    'collectionQuestion',
                    'addData',
                  )}
                  <Tooltip
                    placement={RIGHT}
                    content={getText(LANG_KEY, 'collectionTooltips', 'data')}
                  >
                    <div className="ml-1">
                      <IconInfoCircle className="text-gray-500" size={16} />
                    </div>
                  </Tooltip>
                </label>
                <span className="mb-3 mt-1 text-sm text-gray-500">
                  {getText(
                    { field: fieldName },
                    LANG_KEY,
                    'collectionQuestion',
                    'addDataSubtitle',
                  )}
                </span>
                {collectionData
                  .map(
                    (field: any) =>
                      field || {
                        name: fieldName,
                        type: fieldType,
                        value: field,
                        typeOptions: {
                          format: SINGLE_LINE_TEXT,
                        },
                      },
                  )
                  .map((field: any, index: number) => (
                    <div
                      key={index}
                      className="mb-2 flex items-center border-2 border-transparent"
                    >
                      <span className="py-1.5 pr-4">{index + 1}</span>
                      <DataFieldInput
                        id={`input-${collectionName}-${field.name}`}
                        canEdit={true}
                        dataTypes={dataTypes}
                        disabled={false}
                        field={field}
                        inline={false}
                        onBlur={() => {}}
                        onChange={(value: any) =>
                          setCollectionData(
                            set(index, { ...field, value }, collectionData),
                          )
                        }
                        placeholder={getText(
                          { number: index + 1 },
                          LANG_KEY,
                          'collectionDefaults',
                          'rowPlaceholder',
                        )}
                        value={field.value}
                        projectName={project.name}
                        project={project}
                        surface={LIGHT}
                      />
                    </div>
                  ))}
              </>
            )}
            <Button
              disabled={
                projectLoading ||
                loading ||
                !isDisplayNameValid ||
                !isValid ||
                (addCustomField &&
                  (!fieldName || !fieldType || fieldValidationMessage))
              }
              onClick={handleSave}
              className="mb-3 mt-6 flex w-full items-center justify-center disabled:opacity-50"
            >
              {(projectLoading || loading) && (
                <Loader size="sm" className="mr-4" />
              )}
              {getText(LANG_KEY, 'next')}
            </Button>
            <span className="flex items-center text-sm text-gray-400">
              <IconInfoCircle
                size={16}
                className="mr-2 flex-shrink-0 text-gray-500"
              />
              {getText(LANG_KEY, 'collectionNote')}
            </span>
          </div>
          <div className="col-span-8 w-full px-4 sm:col-span-1 md:col-span-1">
            <h3 className="my-4 flex items-center truncate text-xl font-medium tracking-wider">
              <Icon icon={icon} className="mr-4 h-10 w-8" />
              {collectionName
                ? upperFirst(collectionName)
                : getText(LANG_KEY, 'collectionDefaults', 'collectionName')}
            </h3>
            <div className="w-full max-w-full overflow-x-auto rounded-lg border border-gray-200 bg-white shadow-lg focus:shadow-none focus:outline-none">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="rounded-tl-lg rounded-tr-lg border-b border-gray-200 bg-gray-200 bg-opacity-25 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                  <tr>
                    {(addCustomField || fieldsToCreate.length === 0) && (
                      <th>
                        <div
                          className={classNames(
                            'flex items-center whitespace-nowrap px-3 py-2 pl-6',
                            {
                              'justify-end pr-6':
                                NUMERIC_DATATYPES.includes(fieldType),
                            },
                          )}
                        >
                          {fieldName
                            ? upperFirst(fieldName)
                            : getText(
                                LANG_KEY,
                                'collectionDefaults',
                                'fieldName',
                              )}
                        </div>
                      </th>
                    )}
                    {fieldsToCreate.map((fieldToCreate: SampleField) => (
                      <th key={fieldToCreate.name}>
                        <div
                          className={classNames(
                            'flex items-center whitespace-nowrap px-3 py-2 pl-6',
                            {
                              'justify-end pr-6': NUMERIC_DATATYPES.includes(
                                fieldToCreate.type,
                              ),
                            },
                          )}
                        >
                          {fieldToCreate.name}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {collectionData.map((field: any, index: number) => {
                    const fieldObject = field ?? defaultField;
                    const fieldValue =
                      fieldObject.value ??
                      (!fieldType || fieldType === TEXT
                        ? getText(
                            LANG_KEY,
                            'collectionDefaults',
                            'rowItem',
                            index + 1,
                          )
                        : getSampleFieldValue(fieldObject, index));

                    return (
                      <tr
                        key={index}
                        className="record-recfqzgytaglb08saf3 group relative w-full px-6 text-xs hover:bg-gray-100 hover:bg-opacity-25 focus:shadow-none focus:outline-none"
                      >
                        {(addCustomField || fieldsToCreate.length === 0) && (
                          <td className="max-w-sm py-2 pl-6 pr-6">
                            <div className="mr-auto block w-full max-w-full">
                              <div className="max-w-full whitespace-nowrap">
                                <span className="block">
                                  <FieldCell
                                    className="mr-auto flex w-full flex-col"
                                    showLabel={true}
                                    config={{ name: fieldName }}
                                    dataType={dataType}
                                    field={fieldObject}
                                    layout={TABLE}
                                    permissions={{
                                      read: true,
                                      update: true,
                                      create: true,
                                    }}
                                    record={{
                                      [fieldName]: fieldValue,
                                    }}
                                    value={fieldValue}
                                    project={project}
                                  />
                                </span>
                              </div>
                            </div>
                          </td>
                        )}
                        {fieldsToCreate.map((fieldToCreate: SampleField) => {
                          const fieldValue = getSampleFieldValue(
                            fieldToCreate as DataField,
                            index,
                          );
                          return (
                            <td
                              key={fieldToCreate.name}
                              className="max-w-sm py-2 pl-6 pr-6"
                            >
                              <FieldCell
                                className="mr-auto flex w-full flex-col"
                                showLabel={true}
                                config={{ name: fieldName }}
                                dataType={dataType}
                                field={fieldToCreate}
                                layout={TABLE}
                                permissions={{
                                  read: true,
                                  update: true,
                                  create: true,
                                }}
                                record={{
                                  [fieldName]: fieldValue,
                                }}
                                value={fieldValue}
                                project={project}
                              />
                            </td>
                          );
                        })}
                        <div className="sticky bottom-0 right-2 top-0 z-20 hidden h-full w-0 text-center group-hover:flex">
                          <div className="absolute right-0 my-auto flex h-full items-center gap-x-1"></div>
                        </div>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </AuthWrapperProvider>
  );
};

export default ProjectCreateCollection;
