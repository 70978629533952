import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { BaseModal, Button, Loader, TextArea } from '@noloco/components';
import { DOWNGRADE } from '@noloco/core/src/constants/paymentStatus';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { ONBOARDING_MUTATION } from '../../queries/onboarding';

const LANG_KEY = 'billing.cancel';

const steps = {
  why: 'why',
  like: 'like',
  confirm: 'confirm',
};

const STEP_ORDER = Object.keys(steps);

const CancelSubscriptionModal = ({
  handleChangePlan,
  loading,
  onClose,
}: any) => {
  const { user } = useDashboardAuth();
  const {
    query: { step },
    replaceQueryParams,
    pushQueryParams,
  } = useRouter();
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveOnboardingDetails] = useMutation(ONBOARDING_MUTATION);
  const errorAlert = useGraphQlErrorAlert();
  const [whyText, setWhyText] = useState('');
  const [likedText, setLikedText] = useState('');

  useEffect(() => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (!step || !steps[step]) {
      replaceQueryParams({ step: steps.why });
    }
  });

  const stepIndex = Math.max(STEP_ORDER.indexOf(step), 0);

  const onCancel = useCallback(() => {
    onClose();
    replaceQueryParams({ step: undefined });
  }, [onClose, replaceQueryParams]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (stepIndex < 2) {
        replaceQueryParams({ step: STEP_ORDER[stepIndex + 1] });
      } else {
        setSaveLoading(true);
        saveOnboardingDetails({
          variables: {
            onboardingDetails: { whyCancel: whyText, whatTheyLiked: likedText },
          },
        })
          .then(() => {
            handleChangePlan().then(() => {
              setSaveLoading(false);
              onClose();
              pushQueryParams({ step: undefined, status: DOWNGRADE });
            });
          })
          .catch((error) => {
            errorAlert(getText(LANG_KEY, 'error'), error);
            setSaveLoading(true);
            onCancel();
          });
      }
    },
    [
      errorAlert,
      handleChangePlan,
      likedText,
      onCancel,
      onClose,
      pushQueryParams,
      replaceQueryParams,
      saveOnboardingDetails,
      stepIndex,
      whyText,
    ],
  );

  const isStepValid = useMemo(
    () => [whyText, likedText, true][stepIndex],
    [likedText, stepIndex, whyText],
  );

  return (
    <BaseModal onClose={() => null}>
      <div className="bg-flex w-full flex-col items-center px-16 py-8 pt-16">
        <h2 className="text-center text-sm text-gray-600">
          {getText({ name: user?.firstName }, LANG_KEY, 'title')}
        </h2>
        <h2
          className={classNames('mt-1 text-center font-medium text-gray-800', {
            'text-xl': stepIndex < 2,
            'text-basse': stepIndex >= 2,
          })}
        >
          {getText(LANG_KEY, 'subtitle', step)}
        </h2>
        <form onSubmit={handleSubmit} className="mt-8 flex flex-col">
          {stepIndex === 0 && (
            <TextArea
              onChange={({ target: { value } }: any) => setWhyText(value)}
              value={whyText}
              rows={6}
              placeholder={getText(LANG_KEY, 'why.placeholder')}
            />
          )}
          {stepIndex === 1 && (
            <TextArea
              onChange={({ target: { value } }: any) => setLikedText(value)}
              value={likedText}
              rows={6}
              placeholder={getText(LANG_KEY, 'liked.placeholder')}
            />
          )}
          <div className="mt-6 flex w-full items-center justify-between space-x-8">
            <Button
              size="md"
              onClick={onCancel}
              submitFormOnClick={false}
              disabled={saveLoading || loading}
              type="text"
            >
              {getText(LANG_KEY, 'cancel')}
            </Button>
            <Button
              size="lg"
              submitFormOnClick={true}
              disabled={!isStepValid || saveLoading || loading}
            >
              {!loading && !saveLoading && (
                <span>
                  {getText(LANG_KEY, stepIndex < 2 ? 'continue' : 'confirm')}
                </span>
              )}
              {(loading || saveLoading) && (
                <Loader className="mx-auto" size="sm" />
              )}
            </Button>
          </div>
        </form>
      </div>
    </BaseModal>
  );
};

export default CancelSubscriptionModal;
