import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { IconArrowLeft, IconFileImport } from '@tabler/icons-react';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ErrorText, Loader } from '@noloco/components';
import NolocoIcon from '@noloco/core/src/components/NolocoIcon';
import { CLIENT_AUTH_TOKEN } from '@noloco/core/src/constants/auth';
import { COMPANY, USER } from '@noloco/core/src/constants/builtInDataTypes';
import {
  AIRTABLE,
  CSV,
  DataSourceType,
  GOOGLE_SHEETS,
  INTERNAL,
  MYSQL,
  OnboardingDataSourceChoice,
  POSTGRES,
  SMART_SUITE,
  XANO,
} from '@noloco/core/src/constants/dataSources';
import { BLANK_PORTAL } from '@noloco/core/src/constants/projects';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import { setProject as setProjectState } from '@noloco/core/src/reducers/project';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import {
  BACK_BUTTON,
  BRING_TO_APP,
  DATA_SOURCE_SELECTED,
  PROJECT_CREATED,
  trackChoseDataSource,
  trackEvent,
  trackNewProjectWizardBackClicked,
  trackNewProjectWizardNextClicked,
} from '@noloco/core/src/utils/analytics';
import { isTrailingPlanExpired } from '@noloco/core/src/utils/billing';
import { getTextFromError } from '@noloco/core/src/utils/hooks/useAlerts';
import {
  saveUserData,
  useDashboardAuth,
} from '@noloco/core/src/utils/hooks/useAuth';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { getProjectNamePlaceholder } from '@noloco/core/src/utils/newProject';
import { SampleField } from '../constants/sampleFields';
import airtableIcon from '../img/airtable-icon.svg';
import googleSheetsLogo from '../img/google-sheets-logo.png';
import mysqlLogo from '../img/mysql-square-logo.png';
import postgresLogo from '../img/postgres-logo.png';
import smartSuiteLogo from '../img/smartsuite-logo.png';
import xanoLogo from '../img/xano-logo.png';
import { ONBOARDING_MUTATION } from '../queries/onboarding';
import { CLONE_PROJECT, PROJECT_QUERY } from '../queries/project';
import { useMutateProjectSettings } from '../utils/hooks/projectHooks';
import useNewProjectName from '../utils/hooks/useNewProjectName';
import useTrackDashboardPage, {
  PageTypes,
} from '../utils/hooks/useTrackDashboardPage';
import { getRandomPageIcon } from '../utils/layout';
import { redirectToProject } from '../utils/project';
import AICollectionBuilder from './AICollectionBuilder';
import ConfirmClose from './ConfirmClose';
import Guide from './Guide';
import OnboardingProgressBar from './OnboardingProgressBar';
import ProjectConnection from './ProjectConnection';
import ProjectCreateCollection from './ProjectCreateCollection';
import ProjectDataSource, { DataSourceConfigItem } from './ProjectDataSource';
import ProjectNameField from './ProjectNameField';
import ProjectWorkspaceSelect from './ProjectWorkspaceSelect';
import AddCsv from './dataTable/AddCsv';

const LANG_KEY = 'newProject';

const steps = {
  TEMPLATES: 'TEMPLATES',
  DETAILS: 'DETAILS',
  CSV: 'CSV',
  DATA_SOURCE: 'DATA_SOURCE',
  CREATE: 'CREATE',
  COLLECTION: 'COLLECTION',
  CONNECTION_DETAILS: 'CONNECTION_DETAILS',
  LOADING: 'LOADING',
};

const stepOrder = [
  steps.DATA_SOURCE,
  steps.DETAILS,
  steps.CREATE,
  steps.COLLECTION,
  steps.CONNECTION_DETAILS,
  steps.CSV,
  steps.LOADING,
];

const FIRST_STEP = stepOrder[0];

const navigation = (
  step: string,
  dataSource: DataSourceType | 'CSV' | null = INTERNAL,
  dataSourceType: OnboardingDataSourceChoice | undefined,
) => {
  let next = null;
  let prev = null;

  switch (step) {
    case steps.TEMPLATES:
      next = steps.DETAILS;
      break;

    case steps.CSV: {
      next = steps.LOADING;
      prev = steps.CREATE;
      break;
    }

    case steps.DETAILS: {
      next = steps.CREATE;
      prev = steps.DATA_SOURCE;
      break;
    }

    case steps.DATA_SOURCE:
      next = steps.DETAILS;
      break;

    case steps.CREATE:
      next =
        dataSourceType === 'csv' || dataSource === CSV
          ? steps.CSV
          : dataSource === INTERNAL
            ? steps.COLLECTION
            : steps.CONNECTION_DETAILS;
      prev = steps.DETAILS;
      break;

    case steps.COLLECTION:
      next = steps.LOADING;
      prev = steps.DETAILS;
      break;

    case steps.CONNECTION_DETAILS:
      next = steps.LOADING;
      prev = steps.DETAILS;
      break;

    case steps.LOADING:
      next = steps.LOADING;
      prev =
        dataSource === INTERNAL ? steps.COLLECTION : steps.CONNECTION_DETAILS;
      break;

    default:
      next = steps.DETAILS;
      break;
  }

  return { next, prev };
};

const formatProjectName = (name: string) =>
  startCase(kebabCase(name).replace(/-/g, ' '));

const NewProject = () => {
  const { user } = useDashboardAuth();
  const dispatch = useDispatch();
  useTrackDashboardPage(PageTypes.NEW_PROJECT);

  const {
    push,
    replaceQueryParams,
    query: {
      step: paramStep = steps.DATA_SOURCE,
      appDescription: paramAppDescription,
      onboarding = 'true',
      project: paramProject,
      dataSource: paramDataSource,
      dataSourceType: paramDataSourceType,
    },
  }: any = useRouter();

  const firstName = useMemo(
    () => get(user, 'firstName', '').toLowerCase().replace(/\s/g, '-'),
    [user],
  );
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    getText('newProject.creation.project'),
  );
  const projectNamePlaceholder = useMemo(
    () => getProjectNamePlaceholder(get(user, 'email', ''), firstName),
    [firstName, user],
  );
  const [dataSourceType] = useState<OnboardingDataSourceChoice | undefined>(
    paramDataSourceType,
  );

  const [dataSource, setDataSource] = useState<DataSourceType | 'CSV' | null>(
    null,
  );
  const [dataSourceConfig, setDataSourceConfig] = useState<any>(null);
  const [project, setProject] = useState<Project | null>(null);
  const [step, setStep] = useState(steps.DETAILS);
  const [confirmClose, setConfirmClose] = useState(false);

  const [collectionName, setCollectionName] = useState(null);
  const [fieldName, setFieldName] = useState(null);
  const [fieldType, setFieldType] = useState(null);
  const [fieldsToCreate, setFieldsToCreate] = useState<SampleField[]>([]);
  const [collectionData, setCollectionData] = useState([null, null, null]);
  const [dataType, setDataType] = useState<DataType | undefined>(undefined);
  const projectState = useSelector(projectDataSelector);
  const [workspaceId, setWorkspaceId] = useState<string | undefined>(undefined);
  const [createProject] = useMutation(CLONE_PROJECT);
  const [saveOnboardingDetails] = useMutation(ONBOARDING_MUTATION);
  const [updateSettings] = useMutateProjectSettings();
  const [overrideAI, setOverrideAI] = useState(false);

  const [appDescription] = useState<string | undefined>(paramAppDescription);

  const {
    isNameUnique,
    isNameValid,
    loadingNameCheck,
    onChangeName,
    projectName,
  } = useNewProjectName(projectNamePlaceholder);

  const icon = useMemo(() => ({ name: getRandomPageIcon() }), []);
  const onboardingCompleted = useMemo(
    () => onboarding === 'true',
    [onboarding],
  );

  const projectId = (project && project.name) || paramProject;

  const { loading: projectLoading, data: { project: rawProject = null } = {} } =
    useQuery(PROJECT_QUERY, {
      skip: !!project || !projectId,
      variables: {
        projectId,
      },
    });

  const dataSourceConfigs: Record<string, DataSourceConfigItem> = useMemo(
    () => ({
      [INTERNAL]: {
        LogoComponent: NolocoIcon,
        to: '/_/data/internal/~new/intro',
        langKey: 'internal',
      },
      [CSV]: {
        LogoComponent: IconFileImport,
        langKey: 'csv',
      },
      [AIRTABLE]: {
        logo: airtableIcon,
        to: '/_/setup/airtable',
        langKey: 'airtable',
        guide: 'https://guides.noloco.io/data/airtable',
      },
      [GOOGLE_SHEETS]: {
        logo: googleSheetsLogo,
        to: '/_/setup/google-sheets',
        langKey: 'googleSheets',
        guide: 'https://guides.noloco.io/data/google-sheets',
      },
      [MYSQL]: {
        logo: mysqlLogo,
        to: '/_/setup/mysql',
        langKey: 'mysql',
        guide: 'https://guides.noloco.io/data/mysql',
      },
      [POSTGRES]: {
        logo: postgresLogo,
        to: '/_/setup/postgres',
        langKey: 'postgres',
        guide: 'https://guides.noloco.io/data/postgresql',
      },
      [XANO]: {
        logo: xanoLogo,
        to: '/_/setup/xano',
        langKey: 'xano',
        guide: 'https://guides.noloco.io/data/xano',
      },
      [SMART_SUITE]: {
        guide: 'https://guides.noloco.io/data/smartsuite',
        logo: smartSuiteLogo,
        langKey: 'smartSuite',
        to: '/_/setup/smart-suite',
      },
    }),
    [],
  );

  const projectData = useMemo(
    () => rawProject || project,
    [rawProject, project],
  );

  const handleError = useCallback((error: any) => {
    const errorText = error && getTextFromError(error);
    setError(
      errorText ? errorText.title : getText('newProject.errors.generic'),
    );
    setLoading(false);
    setLoadingMessage('');
    setStep((currentStep) => stepOrder[stepOrder.indexOf(currentStep) - 1]);
  }, []);

  const onCreate = useCallback(async () => {
    setError(null);
    setLoading(true);
    setStep(steps.CREATE);

    const nextStep = navigation(steps.CREATE, dataSource, dataSourceType).next;

    if (project) {
      setLoading(false);
      setStep(nextStep);

      return replaceQueryParams({
        step: nextStep,
        project: projectName || projectData.name,
        dataSource,
      });
    }

    try {
      const options = {
        variables: {
          teamId: parseInt(workspaceId!, 10),
          name: projectName,
          baseName: BLANK_PORTAL,
        },
      };

      const { data } = await createProject(options);
      const clonedProject = data.cloneProject;

      if (clonedProject) {
        setLoadingMessage(getText('newProject.creation.details'));

        const {
          data: { updateProject: updatedProject },
          // @ts-expect-error TS(2349): This expression is not callable. Not all constituents of type 'MutationResult<any> ... are callable.
        } = await updateSettings(
          [],
          {
            ...clonedProject.settings,
            title: formatProjectName(projectName),
            description: formatProjectName(projectName),
          },
          projectName,
        );

        const project = {
          ...clonedProject,
          ...updatedProject,
        };
        setProject(project);
        dispatch(setProjectState(project));

        setLoadingMessage(getText('newProject.creation.finish'));

        return setTimeout(() => {
          setLoading(false);
          setStep(nextStep);
          trackEvent(
            PROJECT_CREATED,
            'name',
            projectName || (projectData && projectData.name),
          );

          replaceQueryParams({
            step: nextStep,
            project: projectName || projectData.name,
            dataSource,
          });
        }, 1000);
      }
    } catch (e) {
      handleError(e);
      console.log(e);
    }
  }, [
    dataSource,
    dataSourceType,
    project,
    replaceQueryParams,
    projectName,
    projectData,
    workspaceId,
    createProject,
    updateSettings,
    dispatch,
    handleError,
  ]);

  const handleBack = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      trackNewProjectWizardBackClicked(step);
      setConfirmClose(false);

      if (step === steps.DATA_SOURCE) {
        return push('/');
      }

      const prevStep = navigation(step, dataSource, dataSourceType).prev;

      replaceQueryParams({ step: prevStep });
      setStep(prevStep as string);

      trackEvent(BACK_BUTTON, 'step', step);
    },
    [step, dataSource, dataSourceType, replaceQueryParams, push],
  );

  const handleNextOrSubmit = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      trackNewProjectWizardNextClicked(step);

      const stepIndex = stepOrder.indexOf(step);
      const nextStep = navigation(step, dataSource, dataSourceType).next;

      if (stepIndex < stepOrder.length - 1) {
        setStep(nextStep);
      }

      replaceQueryParams({ step: nextStep });

      if (nextStep === steps.LOADING) {
        trackEvent(BRING_TO_APP);
        saveOnboardingDetails({
          variables: { onboardingDetails: { onboardingCompleted: true } },
        });

        return redirectToProject(
          `${projectData.name}/${
            dataSource === INTERNAL && dataType ? dataType.name : ''
          }${onboardingCompleted ? '' : '?__onboardingModal=true'}`,
        );
      }

      if (nextStep === steps.CREATE) {
        onCreate();
      }
    },
    [
      step,
      dataSource,
      dataSourceType,
      replaceQueryParams,
      saveOnboardingDetails,
      projectData,
      dataType,
      onboardingCompleted,
      onCreate,
    ],
  );

  const handleChooseDataSource = useCallback(
    (event: SyntheticEvent, newDataSourceOption: DataSourceType | 'CSV') => {
      event.preventDefault();

      if (!dataSource) {
        trackChoseDataSource(newDataSourceOption);
      }

      setDataSource(newDataSourceOption);
      setDataSourceConfig(dataSourceConfigs[newDataSourceOption]);
      trackEvent(DATA_SOURCE_SELECTED, 'dataSource', newDataSourceOption);

      const { disabled = false } = dataSourceConfigs[newDataSourceOption];

      if (!disabled) {
        handleNextOrSubmit(event);
      }
    },
    [dataSource, dataSourceConfigs, handleNextOrSubmit],
  );

  useEffect(() => {
    if (projectData) {
      const selectedDataType: DataType | undefined = first(
        projectData.dataTypes.filter(
          (dataType: DataType) =>
            dataType.source.type === dataSource &&
            ![USER, COMPANY].includes(dataType.name),
        ),
      );

      setProject(projectData);
      dispatch(setProjectState(projectData));
      if ((project && dataSource !== INTERNAL) || !project) {
        setDataType(selectedDataType);
      }
    }
  }, [project, projectData, dispatch, setProject, dataSource, setDataType]);

  useEffect(() => {
    if (!stepOrder.includes(paramStep)) {
      return setStep(FIRST_STEP);
    }

    setStep(paramStep);

    if (
      Object.keys(dataSourceConfigs).includes(paramDataSource) &&
      !dataSource
    ) {
      setDataSource(paramDataSource);
      setDataSourceConfig(dataSourceConfigs[paramDataSource]);
    }
  }, [
    paramStep,
    paramDataSource,
    setStep,
    step,
    dataSource,
    dataSourceConfigs,
  ]);

  useEffect(() => {
    if (projectData) {
      saveUserData(CLIENT_AUTH_TOKEN, projectData.apiKeys.project);
    }
  }, [projectData]);

  const stepValidation = [
    !!dataSource,
    !loadingNameCheck && isNameUnique && isNameValid && workspaceId,
    !loading,
    collectionName && ((fieldName && fieldType) || fieldsToCreate.length > 1),
    false,
    false,
    false,
  ];

  const selectedDisabledDataSource =
    dataSource && dataSourceConfigs[dataSource].disabled;

  if (step === steps.LOADING) {
    return (
      <div className="fixed inset-x-0 inset-y-0 flex w-full flex-col items-center justify-center bg-slate-800 bg-opacity-75 p-16 text-teal-500">
        <Loader className="mx-auto" size="lg" />
      </div>
    );
  }

  return (
    <div className="flex h-screen max-h-screen w-full select-none justify-center overflow-y-auto bg-gray-50 bg-cover bg-no-repeat px-16 pt-12 sm:px-4 md:px-8">
      <form
        onSubmit={handleNextOrSubmit}
        className={classNames('mx-auto flex w-full flex-col pb-4', {
          'max-w-screen-xl': step !== steps.DETAILS,
          'max-w-screen-md': step === steps.DETAILS,
          'max-w-2xl p-16': step === steps.DETAILS,
        })}
      >
        {error && <ErrorText className="mb-4 mt-2 text-lg">{error}</ErrorText>}
        <OnboardingProgressBar value={stepOrder.indexOf(step) + 2} max={9} />
        <h1 className="my-3 flex items-center text-2xl font-extrabold leading-9 text-gray-900">
          {step !== steps.CREATE && (
            <IconArrowLeft
              size={28}
              className="mr-2 cursor-pointer text-gray-400 hover:text-pink-500"
              onClick={handleBack}
            />
          )}
          {project && step === steps.DATA_SOURCE && (
            <ConfirmClose
              project={project}
              step={step}
              steps={steps}
              onboardingCompleted={onboardingCompleted}
              confirmClose={confirmClose}
              setConfirmClose={setConfirmClose}
              push={push}
            />
          )}
          {step === steps.CONNECTION_DETAILS &&
          dataSourceConfig &&
          dataSource ? (
            <div className="flex w-full justify-between">
              <div>
                <h1 className="mb-2 text-sm font-medium text-gray-400">
                  {getText('data', dataSourceConfig.langKey, 'title')}
                </h1>
                <div className="flex items-center">
                  <img
                    src={dataSourceConfig.logo}
                    alt={dataSource}
                    className="mr-2 h-8"
                  />
                  {getText('leftSidebar.data.sources', dataSource)}
                </div>
              </div>
              <div className="flex items-center">
                <Guide className="mt-4 text-sm" href={dataSourceConfig.guide}>
                  {getText('data', dataSourceConfig.langKey, 'help')}
                </Guide>
              </div>
            </div>
          ) : (
            getText(LANG_KEY, step)
          )}
        </h1>
        {step === steps.DETAILS && (
          <div className="mx-auto w-full max-w-screen-md">
            <ProjectNameField
              isNameValid={isNameValid}
              isNameUnique={isNameUnique}
              label={getText('newProject.name.label')}
              loadingNameCheck={loadingNameCheck}
              onChangeName={onChangeName}
              placeholder={projectNamePlaceholder}
              projectName={projectName}
            />
            <ProjectWorkspaceSelect
              className="mb-8 mt-4"
              onChange={setWorkspaceId}
              value={workspaceId}
              isTeamDisabled={isTrailingPlanExpired}
              disabledText={getText('workspaces.select.expired')}
            />
            <Button
              disabled={!stepValidation[stepOrder.indexOf(step)]}
              onClick={handleNextOrSubmit}
              className="mb-3 w-full"
            >
              {getText(LANG_KEY, 'next')}
            </Button>
          </div>
        )}
        {step === steps.CSV && !projectLoading && projectState && (
          <AddCsv
            inOnboarding={true}
            onCancel={handleBack}
            onConfirm={handleNextOrSubmit}
            project={projectState}
          />
        )}
        {step === steps.DATA_SOURCE && (
          <ProjectDataSource
            dataSourceChoice={dataSourceType}
            dataSource={dataSource!}
            dataSourceConfig={dataSourceConfigs}
            handleChooseDataSource={handleChooseDataSource}
            selectedDisabledDataSource={selectedDisabledDataSource ?? false}
          />
        )}
        {step === steps.COLLECTION &&
          !projectLoading &&
          projectState &&
          overrideAI && (
            <ProjectCreateCollection
              project={projectState}
              projectLoading={projectLoading}
              collectionName={collectionName}
              setCollectionName={setCollectionName}
              fieldName={fieldName}
              setFieldName={setFieldName}
              collectionData={collectionData}
              setCollectionData={setCollectionData}
              setFieldType={setFieldType}
              setFieldsToCreate={setFieldsToCreate}
              fieldType={fieldType}
              fieldsToCreate={fieldsToCreate}
              dataType={dataType}
              setDataType={setDataType}
              handleNextOrSubmit={handleNextOrSubmit}
              isValid={stepValidation[stepOrder.indexOf(step)]}
              icon={icon}
            />
          )}
        {step === steps.COLLECTION &&
          !projectLoading &&
          projectState &&
          !overrideAI && (
            <AICollectionBuilder
              defaultAppDescription={appDescription}
              handleNextOrSubmit={handleNextOrSubmit}
              onSwitchToManual={() => setOverrideAI(true)}
              project={projectState}
            />
          )}
        {step === steps.CONNECTION_DETAILS &&
          !projectLoading &&
          projectState && (
            <ProjectConnection
              project={projectState}
              dataSource={dataSource}
              handleNextOrSubmit={handleNextOrSubmit}
            />
          )}
        {step === steps.CREATE && (
          <div className="flex w-full flex-col items-center p-16">
            <Loader className="mx-auto" size="md" />
            <span className="mt-6 text-center">{loadingMessage}</span>
          </div>
        )}
      </form>
    </div>
  );
};

export default NewProject;
