import gql from 'graphql-tag';

const invitedUserFragment = `
    createdAt
    inviteAcceptedAt
    user {
        id
        firstName
        lastName
        email
        createdAt
    }
`;

export const INVITE_USER = gql`
  mutation inviteUser($email: String!, $teamId: Int) {
    inviteUser(email: $email, teamId: $teamId) {
        ${invitedUserFragment}
    }
  }
`;

export const INVITES = gql`
  query invites {
    invites {
        ${invitedUserFragment}
    }
  }
`;
