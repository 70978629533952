import React from 'react';
import { getText } from '@noloco/core/src/utils/lang';
import airReading from '../img/airreading.png';
import redCross from '../img/american-red-cross.png';
import baldridge from '../img/baldridge.png';
import fdWorks from '../img/fdworks.png';
import fergmar from '../img/fergmar.png';
import fiera from '../img/fiera.png';
import flatiron from '../img/flatiron.png';
import gbstax from '../img/gbstax.png';
import airtableBoard from '../img/login/airtable-table.png';
import nolocoUiComponents from '../img/noloco-ui-components.svg';
import phoenix from '../img/phoenix.png';
import saasStock from '../img/saastock.png';

const logos = [
  redCross,
  flatiron,
  saasStock,
  gbstax,
  fergmar,
  fiera,
  phoenix,
  fdWorks,
  baldridge,
  airReading,
];

const SidePanel = () => (
  <div className="flex h-full w-full items-center bg-gray-100 py-32">
    <div className="bg-top-left -mr-16 ml-auto flex h-full w-full max-w-5xl items-center rounded-lg bg-cover bg-no-repeat lg:-mr-16">
      <div className="relative z-20 grid grid-cols-1 grid-rows-1">
        <img
          src={airtableBoard}
          alt="Airtable base"
          className="col-span-1 col-start-1 row-span-1 row-start-1 h-auto w-full rounded shadow"
        />
        <div className="col-span-1 col-start-1 row-span-1 row-start-1 ml-8 mt-10 grid h-auto w-full grid-cols-1 grid-rows-1">
          <img
            src={nolocoUiComponents}
            key={nolocoUiComponents}
            className="left-0 top-0 col-span-1 col-start-1 row-span-1 row-start-1 h-auto w-full rounded"
            alt="Noloco UI Components"
          />
        </div>
        <div className="mt-8 pr-32">
          <h1 className="text-2xl font-bold">
            {getText('auth.testimonial.title')}
          </h1>
          <h2 className="text-lg">{getText('auth.testimonial.description')}</h2>
          <div className="mt-4">
            <h1 className="text-lg">{getText('auth.testimonial.name')}</h1>
            <h1 className="text-sm text-gray-400">
              {getText('auth.testimonial.occupation')},{' '}
              {getText('auth.testimonial.company')}
            </h1>
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 right-0 h-12 w-7/12 overflow-hidden opacity-40">
        <div className="overflow-hidden">
          <div className="img-ticker -mx-4 flex bg-gray-100">
            {logos.map((logo) => (
              <img
                src={logo}
                key={logo}
                className="mx-4 h-8 w-auto flex-none self-start"
                alt={logo}
              />
            ))}
          </div>

          <div className="img-ticker2 absolute top-0 -mx-4 flex bg-gray-100">
            {logos.map((logo) => (
              <img
                src={logo}
                key={logo}
                className="mx-4 h-8 w-auto flex-none self-start"
                alt={logo}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SidePanel;
