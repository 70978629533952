import React, { useEffect } from 'react';
import SimpleLayout from '@noloco/components/src/components/auth/SimpleLayout';
import useRouter from '../utils/hooks/useRouter';
import { getText } from '../utils/lang';
import PoweredByNoloco from './PoweredByNoloco';

const OAuthFinished = () => {
  const {
    query: { error, errorDescription },
  } = useRouter();

  useEffect(() => {
    if (!error) {
      window.close();
    }
  }, [error]);

  return (
    <div className="flex min-h-screen w-full flex-col items-center justify-center overflow-hidden bg-gray-100">
      <SimpleLayout titleText={getText('errors.genericNoRetry')}>
        <div className="mx-2 mt-6 flex flex-col space-y-4 overflow-hidden rounded-lg bg-white p-7 shadow sm:px-4">
          <div className="text-center text-sm">
            {error === 'access_denied'
              ? getText('auth.login.oauth.finished.accessDenied')
              : getText(
                  { error, errorDescription },
                  'auth.login.oauth.finished.error',
                )}
          </div>
        </div>
      </SimpleLayout>
      <PoweredByNoloco
        className="mx-auto flex-wrap justify-center text-gray-800"
        utmSource="noloco_oauth_finished"
      />
    </div>
  );
};

export default OAuthFinished;
