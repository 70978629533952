import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { SelectInput } from '@noloco/components/src';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { BillingPlan } from '@noloco/core/src/models/BillingPlan';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'workspaces.select';

type ProjectWorkspaceSelectProps = {
  className?: string;
  onChange: (value: string) => void;
  value: string | undefined;
  isTeamDisabled?: (billingPlan: BillingPlan) => boolean;
  disabledText?: string;
};

const ProjectWorkspaceSelect = ({
  className,
  onChange,
  value,
  isTeamDisabled = () => false,
  disabledText: disabledTooltipText,
}: ProjectWorkspaceSelectProps) => {
  const {
    query: { workspace },
  } = useRouter();
  const { user } = useDashboardAuth();

  const options = useMemo(
    () =>
      user!.teams.map((team) => {
        const disabled = isTeamDisabled(team.plan);
        return {
          value: team.id,
          label: team.name,
          help: disabled ? disabledTooltipText : undefined,
          disabled,
        };
      }),
    [user, isTeamDisabled, disabledTooltipText],
  );

  useEffect(() => {
    if (options.length === 1 && !value) {
      onChange(options[0].value);
    } else if (workspace && !value) {
      const queryWorkspace = options.find(
        (option) => option.value === String(workspace),
      );
      if (queryWorkspace) {
        onChange(queryWorkspace.value);
      }
    }
  }, [onChange, options, value, workspace]);

  if (options.length === 1) {
    return null;
  }

  return (
    <div className={classNames(className, 'flex items-center space-x-2')}>
      <span className="text-sm">{getText(LANG_KEY, 'label')}</span>
      <SelectInput
        surface={LIGHT}
        value={value}
        onChange={onChange}
        options={options}
        placeholder={getText(LANG_KEY, 'placeholder')}
      />
    </div>
  );
};

export default ProjectWorkspaceSelect;
