import React, { useEffect, useState } from 'react';
import { IconArrowRight } from '@tabler/icons-react';
import get from 'lodash/get';
import { Handle, Position } from 'reactflow';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import { INTERNAL } from '@noloco/core/src/constants/dataSources';
import DataTypes, { DataType } from '@noloco/core/src/models/DataTypes';
import { BaseRecord } from '@noloco/core/src/models/Record';
import DataSourceIcon from '../DataSourceIcon';
import FieldRow from './FieldRow';

type Props = {
  data: {
    dataType: DataType;
    dataTypes: DataTypes;
    record: Promise<BaseRecord | null>;
  };
};

const TableNode = ({ data: { dataType, dataTypes, record } }: Props) => {
  const [resolvedRecord, setResolvedRecord] = useState<BaseRecord | null>(null);

  useEffect(() => {
    record.then(setResolvedRecord);
  }, [record]);

  return (
    <div className="flex w-96 flex-col rounded-lg border bg-white">
      <div className="flex items-center border-b p-3 text-base">
        <Handle
          type="target"
          position={Position.Top}
          id={`${dataType.name}-top`}
          isConnectable={false}
          className="opacity-0"
        />
        <DataSourceIcon type={dataType.source.type} />
        <div className="ml-3 flex w-full flex-wrap items-center">
          {dataType.source.type !== INTERNAL && (
            <span className="mr-2">{dataType.source.display}</span>
          )}
          {dataType.source.type !== INTERNAL && (
            <IconArrowRight className="mr-2" size={16} />
          )}
          <span className="truncate">{dataType.display}</span>
        </div>
      </div>
      <div className="flex flex-col p-1">
        {dataType.fields.map((field) => (
          <div
            className="group relative flex h-8 items-center rounded-lg px-2 text-xs hover:bg-gray-50"
            key={field.name}
          >
            {field.relatedField && field.type !== FILE && (
              <Handle
                type="target"
                position={Position.Left}
                id={`${dataType.name}-${field.name}-left`}
                isConnectable={false}
                className="opacity-0"
              />
            )}
            <FieldRow
              dataTypes={dataTypes}
              field={field}
              value={get(resolvedRecord, field.name)}
            />
            {field.relationship && field.type !== FILE && (
              <Handle
                type="source"
                position={Position.Right}
                id={`${dataType.name}-${field.name}-right`}
                isConnectable={false}
                className="opacity-50"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableNode;
