import React from 'react';
import { Icon } from '@tabler/icons-react';
import classNames from 'classnames';

type OnboardingChoiceItemProps = {
  checked: boolean;
  ChoiceIcon: Icon;
  title: string;
  subtitle?: string;
};

const OnboardingChoiceItem = ({
  checked,
  ChoiceIcon,
  title,
  subtitle,
}: OnboardingChoiceItemProps) => {
  return (
    <div
      className={classNames(
        'flex w-full cursor-pointer items-center rounded-lg border-2 border-transparent bg-gray-100 p-8 text-sm font-medium leading-5 transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none',
        {
          'bg-gray-200 ring ring-pink-300': checked,
        },
      )}
    >
      <ChoiceIcon size={32} className="mr-4 flex-shrink-0 opacity-75" />
      <div className="flex flex-col">
        <span className="text-lg font-medium">{title}</span>
        {subtitle && (
          <span className="text-base font-medium text-gray-500">
            {subtitle}
          </span>
        )}
      </div>
    </div>
  );
};

export default OnboardingChoiceItem;
