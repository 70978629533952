export const toInput = (recommendedDataType: any) => ({
  display: recommendedDataType.display,
  name: recommendedDataType.name,
  fields: recommendedDataType.fields.map((field: any) => ({
    name: field.name,
    display: field.display,
    options: field.options
      ? field.options.map(({ display, name }: any) => ({
          display,
          name,
        }))
      : undefined,
    type: field.type,
    typeOptions: field.typeOptions,
    unique: field.unique,
    relationship: field.relationship,
    reverseDisplay: field.reverseDisplayName,
  })),
});
