import React from 'react';
import ProjectList from './ProjectList';
import WorkspaceList from './WorkspaceList';

const WorkspaceProjectList = () => {
  return (
    <div className="flex md:mb-2 md:flex-col">
      <WorkspaceList path="/apps" />
      <ProjectList />
    </div>
  );
};

export default WorkspaceProjectList;
