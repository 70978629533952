import { useState } from 'react';
import { useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import { PROJECT_NAME_REGEX } from '@noloco/core/src/constants/projects';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { IS_VALID_PROJECT_NAME_QUERY } from '../../queries/project';

const isNameValid = (str: any) => PROJECT_NAME_REGEX.test(str);

const useNewProjectName = (projectNamePlaceholder = '') => {
  const {
    query: { projectName: queryProjectName },
  } = useRouter();
  const [projectName, setProjectName] = useState(
    queryProjectName || projectNamePlaceholder,
  );
  const [debouncedName, setDebouncedName] = useState(projectName);
  const debouncedSetName = debounce(setDebouncedName, 500);

  const isValid =
    !isNil(projectName) && projectName.length > 3 && isNameValid(projectName);
  const {
    loading: loadingNameCheck,
    data: { isValidProjectName: nameIsUnique } = {},
  } = useQuery(IS_VALID_PROJECT_NAME_QUERY, {
    variables: { projectId: debouncedName },
    skip: !isValid,
    fetchPolicy: 'no-cache',
  });

  const onChangeName = ({ target: { value } }: any) => {
    const newName = (value || '').toLowerCase().replace(/\s/g, '-');
    setProjectName(newName);
    debouncedSetName(newName);
  };
  return {
    isNameValid: isValid,
    isNameUnique: nameIsUnique ?? false,
    loadingNameCheck,
    onChangeName,
    projectName,
  };
};

export default useNewProjectName;
