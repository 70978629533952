import { combineReducers } from 'redux';
import billingPlan from '@noloco/core/src/reducers/billingPlan';
import data from '@noloco/core/src/reducers/data';
import elements from '@noloco/core/src/reducers/elements';
import project from '@noloco/core/src/reducers/project';
import queries from '@noloco/core/src/reducers/queries';
import user from '@noloco/core/src/reducers/user';
import teamsUsage from './teamsUsage';

export default combineReducers({
  billingPlan,
  data,
  elements,
  project,
  queries,
  teamsUsage,
  user,
});
