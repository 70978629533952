import { useMemo, useState } from 'react';

export const useCloneDataSource = () => {
  const [externalId, setExternalId] = useState<string | null>(null);

  const dataSourceConnection = useMemo(
    () => ({
      externalId,
    }),
    [externalId],
  );

  const isValidConnection = useMemo(() => !!externalId, [externalId]);

  return {
    dataSourceConnection,
    isValidConnection,
    setExternalId,
  };
};
