const sampleCollectionNames = [
  'Task',
  'Campaign',
  'Project',
  'Account',
  'Request',
  'Event',
  'Objective',
  'Deliverable',
  'Asset',
  'Activity',
  'Address',
  'Allocation',
  'Applicant',
  'Approval',
  'Area',
  'Article',
  'Attendee',
  'Bay',
  'Booking',
  'Brand',
  'Budget',
  'Bug',
  'Buy',
  'Call',
  'Card',
  'Case',
  'Category',
  'Channel',
  'Class',
  'Client',
  'Collaborator',
  'Colleague',
  'Collection',
  'Commitment',
  'Communication',
  'Contact',
  'Contract',
  'Country',
  'Course',
  'Creative',
  'Customer',
  'Date',
  'Delivery',
  'Department',
  'Design',
  'District',
  'Division',
  'Document',
  'Domain',
  'Donation',
  'Email',
  'Employee',
  'Entry',
  'Epic',
  'Episode',
  'Estimate',
  'Facility',
  'Family',
  'Feature',
  'Feedback',
  'Firm',
  'Goal',
  'Group',
  'Guest',
  'Holiday',
  'Idea',
  'Ingredient',
  'Initiative',
  'Insight',
  'Institution',
  'Interaction',
  'Interviewer',
  'Investor',
  'Issue',
  'Item',
  'Job',
  'KPI',
  'Lead',
  'Lesson',
  'Listing',
  'Location',
  'Manifest',
  'Manufacturer',
  'Material',
  'Meeting',
  'Member',
  'Metric',
  'Milestone',
  'Model',
  'Module',
  'Note',
  'Observation',
  'Offer',
  'Office',
  'OKR',
  'Opportunity',
  'Order',
  'Organization',
  'Pain point',
  'Part',
  'Participant',
  'Partner',
  'Payment',
  'Person',
  'Phase',
  'Place',
  'Placement',
  'Plan',
  'Player',
  'Position',
  'Post',
  'Process',
  'Product',
  'Program',
  'Property',
  'Prospect',
  'Quarter',
  'Quote',
  'Rate',
  'Recipe',
  'Region',
  'Registration',
  'Release',
  'Report',
  'Reservation',
  'Resource',
  'Response',
  'Result',
  'Review',
  'Route',
  'Rule',
  'Sample',
  'School',
  'Season',
  'Seat',
  'Series',
  'Service',
  'Session',
  'Shift',
  'Shipment',
  'Show',
  'Site',
  'Skill',
  'Speaker',
  'Sprint',
  'Stage',
  'State',
  'Story',
  'Student',
  'Study',
  'Submission',
  'Subtask',
  'Tag',
  'Team',
  'Teammate',
  'Testimonial',
  'Theme',
  'Ticket',
  'Timeslot',
  'Title',
  'Topic',
  'Tour',
  'Track',
  'Training',
  'Transaction',
  'Type',
  'Unit',
  'Update',
  'Vehicle',
  'Vendor',
  'Version',
  'Visit',
  'Volunteer',
  'Website',
  'Workstream',
  'Zone',
];

export default sampleCollectionNames;
