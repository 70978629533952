import React, { useEffect, useMemo } from 'react';
import get from 'lodash/get';
import { Loader } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { AIRTABLE } from '@noloco/core/src/constants/dataSources';
import { Template, TemplateProject } from '@noloco/core/src/models/Template';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useCacheQuery from '@noloco/core/src/utils/hooks/useCacheQuery';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { TEMPLATE } from '../queries/project';
import CloneDataSource from './dashboard/CloneDataSource';

type Props = {
  dataSourceConnection: { externalId: string | null };
  isValid: boolean;
  onConfirm: () => void;
  projectName: string;
  selectedTemplate: Template | null;
  selectedTemplateProject: TemplateProject | null;
  setSelectedTemplate: (template: Template) => void;
  setSelectedTemplateProject: (template: TemplateProject) => void;
  setExternalId: (externalId: string) => void;
};

const CloneTemplateDataSource = ({
  dataSourceConnection,
  isValid,
  onConfirm,
  projectName,
  selectedTemplate,
  selectedTemplateProject,
  setSelectedTemplateProject,
  setSelectedTemplate,
  setExternalId,
}: Props) => {
  const { user, token } = useDashboardAuth();
  const {
    query: { template: templateId, templateProject: templateProjectId },
  }: any = useRouter();
  const { data: { template: templateData } = {}, loading } = useCacheQuery(
    TEMPLATE,
    {
      skip: selectedTemplate || !templateId,
      variables: { id: templateId },
    },
  );

  const template = useMemo(
    () => (templateData as Template | null) || selectedTemplate,
    [templateData, selectedTemplate],
  );

  const authorizationRedirect = useMemo(
    () =>
      selectedTemplate && selectedTemplateProject
        ? `https://${document.location.hostname}/new?${new URLSearchParams({
            dataSource: AIRTABLE,
            onboarding: 'false',
            step: 'DATA_SOURCE',
            projectName,
            template: String(selectedTemplate.id),
            templateProject: String(selectedTemplateProject.id),
          })}`
        : undefined,
    [projectName, selectedTemplate, selectedTemplateProject],
  );

  const airtableConnected = useMemo(
    () => !!get(user, 'integrations.airtable.id'),
    [user],
  );

  // Added this to show correct template data in the Template Modal if, for any reason, the user decides to go back.
  useEffect(() => {
    if (template) {
      setSelectedTemplate(template);
      const templateProject = template.projects.find(
        ({ id }) => id === parseInt(templateProjectId, 10),
      );
      if (templateProject) {
        setSelectedTemplateProject(templateProject);
      }
    }
  }, [
    template,
    setSelectedTemplate,
    templateProjectId,
    setSelectedTemplateProject,
  ]);

  if ((!selectedTemplate || !selectedTemplateProject) && loading) {
    return <Loader className="mx-auto py-16" />;
  }

  return (
    <CloneDataSource
      airtableConnected={airtableConnected}
      authToken={token}
      airtableBaseLink={get(selectedTemplateProject, 'dataSourceLink')}
      authorizationRedirect={authorizationRedirect}
      dataSourceConnection={dataSourceConnection}
      setExternalId={setExternalId}
      inOnboarding={true}
      onCreate={onConfirm}
      isValid={isValid}
      surface={LIGHT}
    />
  );
};

export default CloneTemplateDataSource;
