import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import { ErrorText, Loader, Modal } from '@noloco/components';
import { INTERNAL } from '@noloco/core/src/constants/dataSources';
import { Template, TemplateProject } from '@noloco/core/src/models/Template';
import { PROJECT_CREATED, trackEvent } from '@noloco/core/src/utils/analytics';
import { getTextFromError } from '@noloco/core/src/utils/hooks/useAlerts';
import { getText } from '@noloco/core/src/utils/lang';
import { CLONE_PROJECT } from '../queries/project';
import { useCheckDataSourceSyncStatus } from '../utils/hooks/useCheckDataSourceSyncStatus';
import { useCloneDataSource } from '../utils/hooks/useCloneDataSource';
import useNewProjectName from '../utils/hooks/useNewProjectName';
import useTrackDashboardPage, {
  PageTypes,
} from '../utils/hooks/useTrackDashboardPage';
import { redirectToProject } from '../utils/project';
import CloneTemplateDataSource from './CloneTemplateDataSource';
import ProjectNameField from './ProjectNameField';
import ProjectWorkspaceSelect from './ProjectWorkspaceSelect';

const LANG_KEY = 'templateList.clone';

type CloneTemplateModalProps = {
  onClose: () => void;
  setSelectedTemplate: (template: Template) => void;
  setSelectedTemplateProject: (template: TemplateProject) => void;
  template: Template;
  templateProject: TemplateProject;
};

const STEPS = {
  name: 'NAME',
  dataSource: 'DATA_SOURCE',
};

const STEPS_ORDER = [STEPS.name, STEPS.dataSource];

const CloneTemplateModal = ({
  onClose,
  setSelectedTemplate,
  setSelectedTemplateProject,
  template,
  templateProject,
}: CloneTemplateModalProps) => {
  useTrackDashboardPage(PageTypes.CLONE_TEMPLATE, {
    template: template.id,
    templateName: template.title,
  });
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    getText('newProject.creation.project'),
  );
  const [error, setError] = useState(null);

  const [step, setStep] = useState(STEPS_ORDER[0]);
  const [workspaceId, setWorkspaceId] = useState<string | undefined>(undefined);
  const [createProject] = useMutation(CLONE_PROJECT);

  const hasExternalSource = useMemo(
    () => templateProject.dataSourceType !== INTERNAL,
    [templateProject.dataSourceType],
  );

  const {
    isNameUnique,
    isNameValid,
    loadingNameCheck,
    onChangeName,
    projectName,
  } = useNewProjectName('');

  const handleError = useCallback((error: any) => {
    setLoading(false);
    const errorText = error && getTextFromError(error);
    setError(
      errorText ? errorText.title : getText('newProject.errors.generic'),
    );
    setLoading(false);
    setLoadingMessage('');
  }, []);

  const {
    dataSourceConnection,
    isValidConnection = false,
    setExternalId,
  } = useCloneDataSource();

  const { checkSyncStatus } = useCheckDataSourceSyncStatus(
    projectName,
    redirectToProject,
    redirectToProject,
  );

  const onCreate = useCallback(async () => {
    setLoading(true);
    const options = {
      variables: {
        teamId: parseInt(workspaceId!, 10),
        name: projectName,
        baseName: templateProject.name,
        dataSourceConnection,
        dataSourceType: templateProject.dataSourceType,
      },
    };

    try {
      const { data } = await createProject(options);
      const clonedProject = data.cloneProject;

      if (clonedProject) {
        trackEvent(PROJECT_CREATED, 'name', projectName);

        if (hasExternalSource) {
          setLoadingMessage(getText('newProject.creation.finish'));

          const clonedExternalDataSource = clonedProject.dataTypes.find(
            ({ source }: any) => get(source, 'type', INTERNAL) !== INTERNAL,
          );

          if (clonedExternalDataSource) {
            return checkSyncStatus(clonedExternalDataSource.source);
          }
        } else {
          return redirectToProject(clonedProject.name);
        }
      }
    } catch (e) {
      handleError(e);
      console.log(e);
    }
  }, [
    checkSyncStatus,
    createProject,
    dataSourceConnection,
    handleError,
    hasExternalSource,
    projectName,
    templateProject.dataSourceType,
    templateProject.name,
    workspaceId,
  ]);

  const stepValidation = useMemo(
    () => [
      isNameUnique && isNameValid && !loadingNameCheck && workspaceId,
      isValidConnection,
    ],
    [
      isNameUnique,
      isNameValid,
      isValidConnection,
      loadingNameCheck,
      workspaceId,
    ],
  );

  const handleNextOrSubmit = useCallback(() => {
    if (step === STEPS.dataSource || !hasExternalSource) {
      onCreate();
    } else {
      setStep(STEPS_ORDER[STEPS_ORDER.indexOf(step) + 1]);
    }
  }, [hasExternalSource, onCreate, step]);

  return (
    <Modal
      title={
        loading
          ? getText(
              {
                templateName: template.title,
              },
              LANG_KEY,
              'loading',
            )
          : getText(LANG_KEY, 'steps', step, 'title')
      }
      size={!loading && step === STEPS.dataSource ? 'xl' : 'md'}
      canCancel={step === STEPS.name}
      canConfirm={step === STEPS.name}
      confirmText={getText(LANG_KEY, 'steps', step, 'next')}
      confirmDisabled={!stepValidation[STEPS_ORDER.indexOf(step)]}
      onConfirm={handleNextOrSubmit}
      onClose={onClose}
      onCancel={onClose}
    >
      {error && !loading && (
        <ErrorText className="mb-4 mt-2 text-lg">{error}</ErrorText>
      )}
      {loading && (
        <div className="flex flex-col items-center justify-center p-6">
          <Loader />
          {loadingMessage && (
            <span className="mt-4 tracking-wide text-gray-600">
              {loadingMessage}
            </span>
          )}
        </div>
      )}
      {!loading && step === STEPS.name && (
        <div className="mx-auto w-full max-w-screen-md">
          <ProjectNameField
            isNameValid={isNameValid}
            isNameUnique={isNameUnique}
            label={getText('newProject.name.label')}
            loadingNameCheck={loadingNameCheck}
            onChangeName={onChangeName}
            placeholder=""
            projectName={projectName}
          />
          <ProjectWorkspaceSelect
            className="mt-4"
            onChange={setWorkspaceId}
            value={workspaceId}
          />
        </div>
      )}
      {!loading && step === STEPS.dataSource && (
        <CloneTemplateDataSource
          dataSourceConnection={dataSourceConnection}
          isValid={stepValidation[STEPS_ORDER.indexOf(STEPS.dataSource)]}
          onConfirm={onCreate}
          projectName={projectName}
          selectedTemplate={template}
          selectedTemplateProject={templateProject}
          setSelectedTemplate={setSelectedTemplate}
          setSelectedTemplateProject={setSelectedTemplateProject}
          setExternalId={setExternalId}
        />
      )}
    </Modal>
  );
};

export default CloneTemplateModal;
