import { MONTH, YEAR } from './accountPlanIntervals';
import {
  BUSINESS,
  BUSINESS_V2,
  FREE,
  PRO,
  PROFESSIONAL,
  PRO_V2,
  SCALE,
  STARTER,
  STARTER_V2,
  TEAM,
} from './accountPlans';

export const PLAN_PRICES = {
  [MONTH]: {
    [FREE]: 0,
    [STARTER]: 99,
    [STARTER_V2]: 49,
    [PROFESSIONAL]: 199,
    [PRO]: 19,
    [PRO_V2]: 149,
    [TEAM]: 299,
    [SCALE]: 799,
    [BUSINESS]: 35,
    [BUSINESS_V2]: 319,
  },
  [YEAR]: {
    [FREE]: 0,
    [STARTER]: 99,
    [STARTER_V2]: 39,
    [PROFESSIONAL]: 199,
    [PRO]: 15,
    [PRO_V2]: 119,
    [TEAM]: 239,
    [SCALE]: 639,
    [BUSINESS]: 28,
    [BUSINESS_V2]: 255,
  },
};

export const EXTERNAL_USER_ADDON_ACTIVATION_PRICES = {
  [PRO]: 119,
  [BUSINESS]: 179,
};

export const EXTERNAL_USER_ADDON_ADDITIONAL_USERS_PRICES = {
  [PRO]: 99,
  [BUSINESS]: 129,
};
